export const bulk_actions_store = {
    state: {
        users: [],
    },
    mutations: {
        ADD_USERS_FOR_BULK_ACTIONS(state, users) {
            state.users.push(...users)
        },
        REMOVE_USER_FROM_BULK_ACTIONS(state, user) {
            state.users = state.users.filter(curr => curr.id !== user.id)
        },
        CLEAR_ALL_USERS_BULK_ACTIONS(state) {
            state.users = []
        }
    }
}
