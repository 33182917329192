<template>

  <div>
      <v-stepper v-model="stepper">
          <v-stepper-step
              editable
              step="1"
              :complete="stepper > 1"
          >
            Upload CSV or type usernames/emails
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card  :disabled="deleteInProgress">
              <v-card-text>
                <v-file-input
                    v-model="files"
                    truncate-length="15"
                    placeholder="Upload your CSV"
                    @change="loadCSV"

                ></v-file-input>
                <v-combobox
                    v-model="usernames"
                    :items="usernames"
                    label="Users"
                    multiple
                    chips
                    :disabled="deleteInProgress"
                ></v-combobox>
              </v-card-text>
              <v-btn class="mr-2" :disabled="deleteInProgress" color="secondary" @click="reset">Clear List</v-btn>
              <v-btn :disabled="deleteInProgress" color="primary" @click="preview">Continue</v-btn>

            </v-card>
          </v-stepper-content>


          <v-divider></v-divider>

          <v-stepper-step
              step="2"
              :editable="stepper > 1"
              :complete="stepper > 2 && this.table.items.length > 0"
          >
            Preview matches from database
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card :disabled="deleteInProgress">
              <v-data-table :disabled="deleteInProgress"
                              :headers="table.headers"
                              :items="table.items"
                              :loading="table.loading"
                              :options="table.options"
                              item-key="id"
                              class="elevation-1"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-btn :disabled="deleteInProgress" @click="removeItem(item)"
                           x-small
                           color="secondary"
                           dark
                    >
                      don't delete me
                      <v-icon
                          small

                      >
                        mdi-close-circle
                      </v-icon>

                    </v-btn>


                  </template>
                  <template v-slot:item.roles="{ item }">
                    <v-chip v-for="role in item.roles" :key="role"
                            close-icon="mdi-close-outline"
                            :color="getRoleColor(role)"
                            label
                            x-small
                    >{{getRoleName(role)}}</v-chip>
                  </template>
                </v-data-table>
            </v-card>
            <v-btn class="mt-4" color="error" :disabled="deleteInProgress" @click="confirmDelete">Delete all {{table.items.length}} users above</v-btn>
          </v-stepper-content>

          <v-divider></v-divider>

          <v-stepper-step
              step="3"
              :editable="stepper > 2"
              :complete="stepper > 3"
          >
            Confirm Bulk Delete Operation
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-card-text>Type the words <b>delete me</b> and click confirm</v-card-text>
              <v-container :disabled="deleteInProgress">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field :disabled="deleteInProgress" v-model="deletemeInput"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn color="primary" :disabled="deleteInProgress" large @click="triggerDeleteCountdown">Confirm</v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn v-if="deleteInProgress && countdown > 0" color="primary" @click="cancelDelete" :disabled="countdown <= 0">Deleting in {{countdown}}... (Click to Cancel)</v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-progress-linear v-if="!deleteComplete && deleteInProgress && countdown <= 0" indeterminate color="error"/>
              <v-container v-show="deleteComplete">
                <div>{{deleteResponseText}}</div>
                <v-btn @click="reset">Bulk Delete Again</v-btn>
              </v-container>
            </v-card>
          </v-stepper-content>

      </v-stepper>
 </div>
</template>
<script>

import {CMS_API_PREFIX, ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER, ROLES} from "@/constants";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'BulkDelete',
  data() {
    return {
      files: [],
      // usernames: [],
      stepper: 1,
      deletemeInput: '',
      countdownTimeout: null,
      countdown: 15,
      deleteInProgress: false,
      deleteComplete: false,
      deleteResponseText: '',
      table: {
        items: [],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {text: 'Actions', value: 'actions', sortable: false },
          {text: 'Role', value: 'roles', sortable :false},
          {text: 'id', value: 'id'},
          {text: 'username', value: 'username'},
          {text: 'E-Mail', value: 'email'},
          {text: 'First Name', value: 'first_name'},
          {text: 'Last Name', value: 'last_name'},
          {text: 'Last seen at', value: 'last_login_date'},
        ],
        loading: false
      }
    }
  },
  computed: {
    ...mapState(['bulk_delete']),
    usernames: {
      get() {
        return this.bulk_delete.usernames;
      },
      set(value) {
        this.SET_USERNAMES_FOR_BULK_DELETE(value);
      }
    }
  },
  methods: {
    ...mapMutations(['SET_USERNAMES_FOR_BULK_DELETE']),
    reset() {
      this.files = [];
      this.usernames = [];
      this.stepper = 1;
      this.deletemeInput = '';
      if (this.countdownTimeout) clearInterval(this.countdownTimeout);
      this.countdownTimeout = null;
      this.countdown = 15;
      this.deleteInProgress = false;
      this.deleteComplete = false;
      this.table.items = [];
      this.deleteResponseText = '';
    },
    async loadCSV(file) {
      // eslint-disable-next-line no-undef
      if (file) {
        this.$papa.parse(file, {
          complete: (res) => {
            this.usernames = res.data.flatMap(line => line).map(username => username.trim());
          }
        })
      }
    },
    getRoleColor(roleId) {
      if (roleId === ROLE_TEACHER) return 'orange'
      if (roleId === ROLE_STUDENT) return 'yellow'
      if (roleId === ROLE_ADMIN) return 'red'
      return 'blue'
    },
    getRoleName(roleId) {
      return ROLES[roleId];
    },
    removeItem(item) {
      this.table.items = this.table.items.filter(it => it.id !== item.id)
    },
    async preview() {
      this.stepper = 2
      // console.log(this.usernames)
      let url = `${CMS_API_PREFIX}/user/by_usernames?usernames=${encodeURIComponent(this.usernames.join(','))}`;
      this.table.loading = true
      try {
        const {data, status} = await this.axios.get(url);
        if (status !== 200) {
          this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          this.table.loading = false;
          return;
        }
        this.table.items = data.items.map(item => ({...item, roles: JSON.parse(item.roles)}));
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.table.loading = false
      }
    },
    confirmDelete() {
      this.stepper = 3
    },
    async triggerDeleteCountdown() {
      if (this.deletemeInput != 'delete me') return;
      this.countdown = 15;
      this.deleteInProgress = true;
      this.countdownTimeout = setInterval(async function() {
        this.countdown = this.countdown - 1;
        if (this.countdown <= 0) {
          clearInterval(this.countdownTimeout);
          //do delete
          try {
            let url = `/user/delete?ids=${this.table.items.map(item => item.id).join(',')}`;
            let {data} = await this.axios(url);
            if (data && data.success === 'true') {
              this.deleteResponseText = 'deleted successfully, verifying users do not exist...'
              url = `${CMS_API_PREFIX}/user/by_usernames?usernames=${encodeURIComponent(this.table.items.map(item => item.username).join(','))}`;
              let {data} = await this.axios(url);
              this.deleteResponseText = data.items.length > 0 ?
                  `Oops... ${data.items.length} users were not deleted (${encodeURIComponent(data.items.map(item => item.username).join(','))})` : 'All Good!';
            }
            this.deleteComplete = true;
            this.deleteInProgress = false;
          }
          catch(e) {
            this.deleteResponseText = e.message;
          }
        }
      }.bind(this), 1000)
    },
    cancelDelete() {
      this.countdown = 15;
      clearInterval(this.countdownTimeout)
      this.deleteInProgress = false;
      this.deletemeInput = '';
    }
  }
}
</script>
