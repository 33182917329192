import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "main" */ '../views/Home.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "main" */ '../views/Users.vue')
  },
  {
    path: '/students',
    name: 'Students',
    component: () => import(/* webpackChunkName: "support" */ '../views/Students.vue')
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: () => import(/* webpackChunkName: "support" */ '../views/Teachers.vue')
  },
  {
    path: '/user/:id',
    name: 'UserEditor',
    component: () => import(/* webpackChunkName: "support" */ '../views/Users.vue')
  },
  {
    path: '/bulk-delete',
    name: 'BulkDelete',
    component: () => import(/* webpackChunkName: "admin" */ '../views/BulkDelete.vue')
  },
  {
    path: '/bulk-subs',
    name: 'BulkSubs',
    component: () => import(/* webpackChunkName: "admin" */ '../views/BulkSubs.vue')
  },
  {
    path: '/ads-txt',
    name: 'AdsTxt',
    component: () => import(/* webpackChunkName: "adops" */ '../views/AdsTxt.vue')
  },
  {
    path: '/ad-configs',
    name: 'AdConfigs',
    component: () => import(/* webpackChunkName: "adops" */ '../views/AdConfigs.vue')
  },
  {
    path: '/sub-plans',
    name: 'SubscriptionPlans',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/SubTypes.vue')
  },
  {
    path: '/passages',
    name: 'Passages',
    component: () => import(/* webpackChunkName: "marketing" */ '../views/PassagesPage.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "dev" */ '../views/Test.vue')
  },
  {
    path: '/schools',
    name: 'Schools',
    component: () => import(/* webpackChunkName: "dev" */ '../views/School.vue')
  },
  {
    path: '/custom-schools',
    name: 'Custom Schools',
    component: () => import(/* webpackChunkName: "dev" */ '../views/CustomSchools.vue')
  },
  {
    path: '/premium',
    name: 'Premium School Accounts',
    component: () => import(/* webpackChunkName: "dev" */ '../views/PremiumSchool.vue')
  },
  {
    path: '/premium/edit/:id',
    name: 'Premium School Accounts Editor',
    component: () => import(/* webpackChunkName: "dev" */ '../components/school/PremiumSchoolEditor.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
