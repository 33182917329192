import {TreeNode} from "@/components/tree/Nodes/TreeNode";
import {TeacherListNode} from "@/components/tree/Nodes/TeacherListNode";
import {SCHOOL_ACCOUNT_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";

export class SchoolAccountNode extends TreeNode {

    constructor(account, parentNode=null) {
        super(account.id, account.name, SCHOOL_ACCOUNT_NODE_TYPE, parentNode);
        this.school_account = account
        this.icon='mdi-account-star'
        this._initChildren()
    }

    get name() {
        return this.school_account.name
    }

    _initChildren() {
        this.children = [new TeacherListNode(this)]
    }

    getObject() {
        return
    }
}
