<template>
  <v-card>
    <reset-password-dialog ref="resetPasswordDialog" v-on:reset-password-dialog-confirmation="resetPasswords($event)"></reset-password-dialog>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <user-bulk-editor-dialog ref="editor"></user-bulk-editor-dialog>
    <v-toolbar
        dark
        color="secondary"
    >
      <v-toolbar-title>Selected Users</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu
            bottom
            right
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit">
              <v-list-item-icon><v-icon color="blue darken-2">mdi-pencil</v-icon></v-list-item-icon>
              <v-list-item-title>Bulk Edit these {{users.length}} users</v-list-item-title>
            </v-list-item>
            <v-list-item @click="openResetPasswordDialog">
              <v-list-item-icon><v-icon color="blue darken-2">mdi-lock-reset</v-icon></v-list-item-icon>
              <v-list-item-title>Reset passwords for {{users.length}} users</v-list-item-title>
            </v-list-item>
            <v-list-item @click="bulk_subs">
              <v-list-item-icon><v-icon color="green darken-2">mdi-currency-usd</v-icon></v-list-item-icon>
              <v-list-item-title>Move to bulk subscription</v-list-item-title>
            </v-list-item>
            <v-list-item @click="bulk_delete">
              <v-list-item-icon><v-icon color="red darken-2">mdi-delete</v-icon></v-list-item-icon>
              <v-list-item-title>Move to bulk delete</v-list-item-title>
            </v-list-item>
            <v-list-item @click="CLEAR_ALL_USERS_BULK_ACTIONS">
              <v-list-item-icon><v-icon color="darken-2">mdi-close</v-icon></v-list-item-icon>
              <v-list-item-title>Clear all selected users from this list</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-chip v-for="user in users" :key="user.id" @click:close="REMOVE_USER_FROM_BULK_ACTIONS(user)"
          close
          close-icon="mdi-close"
          :color="getColor(user)"
          class="mr-2 mb-2"
      >{{user.username}}</v-chip>
    </v-card-text>
  </v-card>
</template>
<script>
  import _ from 'lodash';
  import {mapActions, mapMutations, mapState} from "vuex";
  import {ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER} from "@/constants";
  import UserBulkEditorDialog from "@/components/UserBulkEditorDialog";
  import ConfirmDialog from "@/components/ConfirmDialog";
  import ResetPasswordDialog from "@/components/ResetPasswordDialog";

  export default {
    name: 'UserBulkActions',
    components: {ResetPasswordDialog, ConfirmDialog, UserBulkEditorDialog},
    data: () => {
      return {}
    },
    computed: {
      ...mapState({
          users: state => state.bulk_actions.users,
          bulk_delete_users: state => state.bulk_delete.usernames,
          bulk_sub_users: state => state.bulk_subscriptions.usernames
      }),
    },
    methods: {
      ...mapActions(['setGlobalLoading']),
      ...mapMutations(
          ['REMOVE_USER_FROM_BULK_ACTIONS', 'CLEAR_ALL_USERS_BULK_ACTIONS',
            'ADD_USERNAMES_FOR_BULK_DELETE', 'SET_USERNAMES_FOR_BULK_DELETE',
            'ADD_USERNAMES_FOR_BULK_SUB' ,'SET_USERNAMES_FOR_BULK_SUB'
          ]),
      getColor(user) {
        const roleId = _.head(user.roles)
        if (roleId === ROLE_TEACHER) return 'orange'
        if (roleId === ROLE_STUDENT) return 'yellow'
        if (roleId === ROLE_ADMIN) return 'red'
        return 'blue'
      },
      bulk_delete() {
        this.SET_USERNAMES_FOR_BULK_DELETE([]);
        this.ADD_USERNAMES_FOR_BULK_DELETE(this.users.map(u => u.username))
        this.$router.push({name: 'BulkDelete'})
      },
      bulk_subs() {
        this.SET_USERNAMES_FOR_BULK_SUB([]);
        this.ADD_USERNAMES_FOR_BULK_SUB(this.users.map(u => u.username))
        this.$router.push({name: 'BulkSubs'})
      },
      async openResetPasswordDialog() {
        this.$refs.resetPasswordDialog.show(` ${this.users.length} users`, {
          id: this.users.length+' users',
          password: '',
          confirmPassword: '',
          mustReset: true
        });
      },

      async resetPasswords(dialogRes) {
        let successAll = true;
        this.setGlobalLoading(true);
        for (let i=0; i< this.users.length; i++) {
          const currUser = this.users[i];
          const payload = {
            userId: currUser.user_id,
            newPassword: dialogRes.password,
            confirmNewPassword: dialogRes.confirmPassword,
            mustReset: dialogRes.mustReset
          };
          let success = await this.resetPassword(payload)
          successAll = successAll && success;
          if (!success) {
            this.notify('Could not reset password for '+currUser.username, 'error')
          }
        }
        this.setGlobalLoading(false);
        this.dialog = false;
        if (successAll) this.$root.$emit('notify', {'message': `Successfully reset passwords of ${this.users.length} users.`, type: 'success'})
      },

      async resetPassword(payload) {
        try {
          const url = `/resetPassword`;
          const {data, status} = await this.axios.post(url, payload)
          if (status !== 200) {
            return false
          }
          if (data.success === true) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
          console.error(e)
        }
      },

      edit() {
        this.$refs['editor'].show(this.users)
      }
    }

  }

</script>
