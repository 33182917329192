export const setAuthToken = (token) => {
    sessionStorage.setItem('token', token)
}

export const getAuthToken = () => {
    // return sessionStorage.getItem('token')
    // for production
    // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjU4MDE1NTAsInJvbGVzIjpbIlJPTEVfVVNFUiIsIlJPTEVfQURNSU4iXSwidGltZXN0YW1wIjoxNjg5MjM5MzY4MTI1LCJpYXQiOjE2ODkyMzkzNjh9.cGzU3w5COk2yJzE9yNdXT62ISdbCvZvHKLK4tfZxa7k'
    // for dev
    return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDksInJvbGVzIjpbIlJPTEVfVVNFUiIsIlJPTEVfQURNSU4iLCJST0xFX1RFQUNIRVIiXSwidGltZXN0YW1wIjoxNjg3MzcyMDk0ODg2LCJpYXQiOjE2ODczNzIwOTR9.WWEy0FNbQgAnSc5No2mJMMR-KrBNwd5NKUgo_G036Iw'
}
