<template>
  <v-row>
    <confirm-dialog ref="confirm"/>
    <ResetPasswordDialog ref="resetPasswordDialog" v-on:reset-password-dialog-confirmation="resetPassword($event)"/>
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
      <div>
        <EditSubscriptionDialog ref="editSubscriptionDialog" v-on:subscription-updated="onSubscriptionUpdated($event)"/>
        <v-card elevation="2">
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit User <b>{{ item.username }}</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="save"
              >
                Save
              </v-btn>
              <v-menu
                  bottom
                  right
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleResetPassword">
                    <v-list-item-icon>
                      <v-icon color="darken-2">mdi-lock-reset</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Reset Password</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="impersonate">
                    <v-list-item-icon>
                      <v-icon color="darken-2">mdi-login</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Impersonate</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isTeacher" @click="showConvertToStudentDialog">
                    <v-list-item-icon>
                      <v-icon color="darken-2">mdi-account-convert</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Convert To Student</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="reset">
                    <v-list-item-icon>
                      <v-icon color="darken-2">mdi-undo</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Undo Unsaved Changes</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDelete">
                    <v-list-item-icon>
                      <v-icon color="red darken-2">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>
          <v-subheader>User Profile
          </v-subheader>
          <div v-if="!this.loading">
            <v-form class="pa-5">
              <v-row class="left">
                <v-col cols="12"
                       sm="12"
                       md="1">
                  <v-text-field
                      v-model="item.id"
                      label="User ID"
                      disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       md="2">
                  <v-text-field
                      v-model="item.create_date"
                      label="Created At"
                      disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       md="4">
                  <v-chip-group class="ml-2">
                    <v-chip v-for="role in item.roles" :key="role"
                            :color="getRoleColor(role)"
                            light
                            label
                    >{{ getRoleName(role) }}
                    </v-chip>
                    <v-chip v-if="isPremium"
                            color="green"
                            label
                            light
                    >
                      <v-icon left>
                        mdi-currency-usd
                      </v-icon>
                      PREMIUM
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       md="2">
                  <v-text-field
                      v-model="item.last_login_date"
                      label="Last Logged in at"
                      disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                       sm="12"
                       md="5">
                  <v-text-field
                      v-model="item.username"
                      label="Username"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       md="5">
                  <v-text-field
                      v-model="item.email"
                      label="Email"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="5"
                >
                  <v-text-field
                      v-model="item.first_name"
                      label="First name"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="5"
                >
                  <v-text-field
                      v-model="item.last_name"
                      label="Last name"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="left" v-if="isTeacher">
                <v-col class="pt-2"
											cols="12"
                      sm="12"
                      md="5">
									<SearchAutoComplete
										class="school-search-select"
										label="School Name"
										placeholder="Search for a school info..."
										:isMultiple="false"
                    :disabled="!!schoolAccount || !!selectedSchoolAccount"
										:searchList="getSchoolDataFromApi"
										:selectedItem="currentSchoolInfo"
										:onSelected="selectSchool"
									>
									</SearchAutoComplete>
                </v-col>
                <v-col cols="12"
                       sm="12"
                       md="5">
                  <v-text-field
                      v-model="user_settings.job_title"
                      label="Job Title"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="left">
                <v-col cols="12"
                       sm="12"
                       md="3">
                  <v-combobox
                      v-model="item.timezone"
                      label="Timezone"
                      :items="timezones"
                      ref="tzCombo"
                  >
                  </v-combobox>

                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12"
                     sm="12"
                     md="5">
                <v-list
                    three-line
                    subheader
                    class="mb-2"
                >
                  <v-subheader>User Settings</v-subheader>
                  <!--<v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="user_settings.new_quiz_ui"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>New Quiz UI</v-list-item-title>
                      <v-list-item-subtitle>For teachers: Enables new quiz UI experience on new classes</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="user_settings.new_class_ui"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>New Class UI</v-list-item-title>
                      <v-list-item-subtitle>Turns on the new class UI for teachers</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>-->
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="user_settings.ads_disabled"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Disable Ads</v-list-item-title>
                      <v-list-item-subtitle>Disables ads to the user, or if a teacher, disables ads for all new classes
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox v-model="user_settings.new_report_ui"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>New Reports UI</v-list-item-title>
                      <v-list-item-subtitle>Enables new reporting UI experience</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12"
                     sm="12"
                     md="5">
                <v-list v-if="isSubscriptionActive"
                        three-line
                        subheader
                >
                  <v-subheader>Subscription</v-subheader>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon v-if="userSubscription.active" color="green">mdi-checkbox-marked-circle</v-icon>
                      <v-icon v-else color="orange">mdi-alert</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="userSubscription.active">
                      <v-list-item-title>Subscription Enabled{{ schoolSubscriptionName }}</v-list-item-title>
                      <v-list-item-subtitle>This user should have a working premium account</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-title>Subscription Disabled{{ schoolSubscriptionName }}</v-list-item-title>
                      <v-list-item-subtitle>This user had a working subscription but was disabled</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="stripeCustomerId">
                    <v-list-item-action>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title><a target="_blank"
                                            :href="'https://dashboard.stripe.com/customers/'+stripeCustomerId">{{stripeCustomerId}}</a>
                      </v-list-item-title>
                      <v-list-item-subtitle>Stripe Customer ID (click to view in stripe)</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!isSubscriptionValid">
                    <v-list-item-action>
                      <v-icon v-if="!isSubscriptionValid" color="red">mdi-alert</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-if="!isSubscriptionValid">
                      <v-list-item-title>Something in this subscription is not saved correctly</v-list-item-title>
                      <v-list-item-subtitle>Please post a message on the #support channel on slack to the devs
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!schoolAccount">
                    <v-btn small color="secondary" @click.stop="handleEditSubscription">Edit Subscription</v-btn>
                  </v-list-item>
                  <v-list-item class="pl-0" v-else>
                    <v-btn @click="removeSchoolAccount">Remove this teacher from school account</v-btn>
                  </v-list-item>
                </v-list>
                <v-list v-else
                        three-line
                        subheader
                        class="mb-2"
                >
                  <v-subheader>No Subscription</v-subheader>
                  <v-btn @click.stop="handleEditSubscription">
                    <v-icon>mdi-currency-usd</v-icon>
                    Create Subscription...
                  </v-btn>
                </v-list>
                <v-list class="py-0" v-if="!schoolAccount">
                  <v-list-item class="pl-0" v-if="isPremiumButtonVisible">
                    <v-btn @click="isPremiumSchoolVisible = true">Connect to premium school account</v-btn>
                  </v-list-item>
                  <v-list-item class="pl-0 pt-4" v-if="isPremiumSchoolVisible">
                    <SearchAutoComplete
                      class="school-account-select"
                      label="School Account Name"
                      placeholder="Search for a school account..."
                      itemType="school-account"
                      :isMultiple="false"
                      :searchList="getSchoolAccountDataFromApi"
                      :selectedItem="schoolAccount"
                      :onSelected="selectSchoolAccount"
                    >
                    </SearchAutoComplete>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>
          <v-skeleton-loader v-else
                             class="'mx-auto pa-3 dark" :boilerplate="false" elevation="0"
                             type="list-item-two-line, list-item-three-line, actions"
          ></v-skeleton-loader>
          <StudentEditor v-if="isStudent" ref="studentEditor"></StudentEditor>
          <TeacherEditor v-if="isTeacher" ref="teacherEditor"></TeacherEditor>
          <v-row>
            <v-col cols="12">
              <UserTree v-if="!this.loading" :root-object="treeRootNode"></UserTree>
            </v-col>
          </v-row>
        </v-card>

      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import _ from 'lodash';
import {EventBus, NOTIFY_EVENT, USER_UPDATED_EVENT} from "@/eventbus";
import {ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER, ROLES, TIMEZONES} from "@/constants";
import StudentEditor from "@/components/StudentEditor";
import TeacherEditor from "@/components/TeacherEditor";
import ConfirmDialog from "@/components/ConfirmDialog";
import {mapState} from "vuex";
import ResetPasswordDialog from '@/components/ResetPasswordDialog';
import EditSubscriptionDialog from "@/components/EditSubscriptionDialog";
import SearchAutoComplete from '@/components/school/SearchAutoComplete.vue';
import UserTree from "@/components/tree/UserTree";
import {STUDENT_NODE_TYPE, TEACHER_NODE_TYPE} from "@/components/tree/Nodes/user_tree_node_types";
import {
  getSchools,
  searchSchools,
  getPremiumSchools,
  searchPremiumSchools,
  getUserSchool,
  connectSchoolToUser,
  removeSchoolFromUser,
  getUserSchoolAccount,
  connectSchoolAccountToUser,
  removeSchoolAccountFromUser
} from '@/api/api'

const DEFAULT_USER_SETTINGS = {
  new_class_ui: true,
  new_quiz_ui: true,
  ads_disabled: false,
  new_report_ui: false
}

export default {
  name: 'UserEditorDialog',
  props: {
    onDelete: {
      type: Function,
      default: () => {
      },
    },
    onClose: {
      type: Function,
      default: () => {
      },
    }
  },
  watch: {
    dialog: function (newVal) {
      if (!newVal) this.onClose()
    }
  },
  components: {
    EditSubscriptionDialog,
    TeacherEditor, StudentEditor, ConfirmDialog, ResetPasswordDialog, UserTree, SearchAutoComplete
  },
  computed: {
    ...mapState(['user', 'groups']),
    timezones() {
      return TIMEZONES.map(tz => tz.zone_id)
    },
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    },
    isTeacher() {
      return this.item?.type === 'teacher' || this.item?.roles?.indexOf(ROLE_TEACHER) > -1
    },
    isStudent() {
      return this.item?.type === 'student' || this.item?.roles?.indexOf(ROLE_STUDENT) > -1
    },
    isAdmin() {
      return this.item?.roles?.indexOf(ROLE_ADMIN) > -1
    },
    isSubscription() {
      return !_.isNil(_.result(this.schoolAccount, 'subscription', null)) ||
        !_.isNil(_.result(this.item, 'user_settings.subscription', null))
    },
    stripeCustomerId() {
      if (this.schoolAccount) {
        return this.schoolAccount.subscription.customer_id
      } else {
        return _.result(this.item, 'user_settings.subscription.customer_id', null);
      }
    },
    isSubscriptionValid() {
      return  ((!_.isNil(this.schoolAccount) && !_.isNil(this.schoolAccount.subscription)) ||
        !_.isNil(_.result(this.item, 'user_settings.subscription', null))) &&
        !_.isNil(this.stripeCustomerId)
    },
    isPremiumButtonVisible() {
      return this.schoolAccount || !this.schoolAccount && !this.isPremiumSchoolVisible;
    },
    userSubscription() {
      return this.schoolAccount ? this.schoolAccount.subscription : this.user_settings.subscription
    },
    isSubscriptionActive() {
      return this.schoolAccount || this.userSubscription?.active
    },
    isPremium() {
      return this.userSubscription?.active
    },
    schoolSubscriptionName() {
      return this.schoolAccount ? `(${this.schoolAccount.name})` : ''
    },
    currentSchoolInfo() {
      if (this.selectedSchoolAccount) {
        return {
          id: this.selectedSchoolAccount.school_info_id,
          name: this.selectedSchoolAccount.name
        }
      } else {
        return this.userSchool
      }
    }
  },
  methods: {
    async impersonate() {
      window.open(`https://readtheory.org/j_spring_security_switch_user?j_username=${this.item.username}`)
    },

    async resetPassword(item) {
      try {
        console.log(item);
        const userData = {
          userId: item.user_id,
          newPassword: item.password,
          confirmNewPassword: item.confirmPassword,
          mustReset: item.mustReset
        };
        const url = `/resetPassword`;
        const {data, status} = await this.axios.post(url, userData)

        if (status !== 200) {
          this.notify(`Error: server returned status: ${status}`, 'error')
        }
        if (data.success === true) {
          this.notify(`User password was updated`, 'info')
        } else {
          this.notify(`Error: server returned: ${data.message}`, 'error')
        }
      } catch (e) {
        console.error(e)
      }
    },
    getRoleColor(roleId) {
      if (roleId === ROLE_TEACHER) return 'orange'
      if (roleId === ROLE_STUDENT) return 'yellow'
      if (roleId === ROLE_ADMIN) return 'red'
      return 'blue'
    },
    getRoleName(roleId) {
      return ROLES[roleId];
    },

    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async show(item) {
      this.loading = true
      this.item = null;
      this.dialog = true;
      this.originalItem = item;
      this.item = _.cloneDeep(this.originalItem);
      this.isPremiumSchoolVisible = false
      this.initSchoolInfo()
      await this.refreshData();
      this.loading = false
    },
    async reset() {
      this.loading = true;
      this.item = _.cloneDeep(this.originalItem);
      // this.item = _.cloneDeep(this.originalItem);
      this.item.user_settings = _.defaults(this.item.user_settings, DEFAULT_USER_SETTINGS)
      this.user_settings = this.item.user_settings
      this.$nextTick(async () => {
        if (this.isStudent) {
          this.item.student = await this.$refs.studentEditor.load(this.item.user_id)
          this.treeRootNode = _.cloneDeep(this.item);
          this.treeRootNode.id = this.treeRootNode.student.id;
        } else if (this.isTeacher) {
          this.item.teacher = await this.$refs.teacherEditor.load(this.item.user_id)
          this.treeRootNode = _.cloneDeep(this.item);
          this.treeRootNode.id = this.treeRootNode.teacher.id;
        }
        this.treeRootNode.user_id = this.item.id;
        this.treeRootNode.type = this.isTeacher ? TEACHER_NODE_TYPE : STUDENT_NODE_TYPE;
        this.loading = false;
      })
    },
		async getSchoolDataFromApi(search) {
      let schoolData = []
      if (!search) {
        schoolData = await getSchools(1, 10)
      } else {
        schoolData = await searchSchools(search, 1, 10)
      }
      this.schoolList = schoolData || []
      return this.schoolList
    },
		selectSchool(schoolId) {
      if (schoolId) {
        this.selectedSchool = this.schoolList.find(schoolItem => schoolItem.id === schoolId)
				this.user_settings.school_name = this.selectedSchool.name
      } else {
        this.selectedSchool = null
				this.user_settings.school_name = null
      }
    },
    handleDelete() {
      this.dialog = false;
      this.onDelete(this.item)
    },
    handleResetPassword() {
      this.$refs.resetPasswordDialog.show(this.item.username, this.item);
    },
    handleEditSubscription() {
      this.$refs.editSubscriptionDialog.show(this.item.email, this.item.id, this.item.user_settings.subscription);
    },
    onSubscriptionUpdated(subscriptionObj) {
      //console.log(e)
      this.refreshData()
      // Vue.set(this.user_settings, 'subscription', subscriptionObj);
      // this.user_settings.ads_disabled = true;
    },

    async showConvertToStudentDialog(user_id) {
      const confirmed = await this.$refs.confirm.open(
          "Confirm",
          "All classes and connections to tracked students will be deleted, Are you sure you want to convert this teacher into a student?"
      )
      if (confirmed) {
        this.loading = true;
        await this.convertToStudent(user_id);
        this.loading = false;
      }
    },
    async convertToStudent() {
      try {
        const url = `/user/${this.item.user_id}/actions/convert_to_student`
        const {data, status} = await this.axios.post(url)
        if (status !== 200) {
          this.notify(`Error: server returned status: ${status}`, 'error')
        }
        if (data.success === true) {
          this.dialog = false;
          this.originalItem.type = 'student';
          const idx = this.originalItem?.roles?.indexOf(ROLE_TEACHER) ?? -1;
          if (idx > -1) {
            _.set(this.originalItem, `roles[${idx}]`, ROLE_STUDENT)
          }
          EventBus.$emit(USER_UPDATED_EVENT, this.originalItem);
        } else {
          this.notify(`Error: server returned: ${data.message}`, 'error')
        }
      } catch (e) {
        console.error(e)
      }

    },
    async getSchoolData() {
      try {
        let result = await getUserSchoolAccount(this.item.id)
        const accountInfo = result.data
        this.schoolAccount = accountInfo

        result = await getUserSchool(this.item.id)
        if (accountInfo) {
          this.userSchool = {
            id: accountInfo.school_info_id,
            name: accountInfo.name
          }
          this.accountSchoolInfo = result.data
        } else {
          this.userSchool = result.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    async refreshData() {
      const url = `/user/${this.item.id}`
      const {data, status} = await this.axios(url, {cancelToken: this.cancelToken});
      if (status !== 200) {
        this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
      }
      this.getSchoolData()
      this.originalItem = _.head(data.items);
      if (!_.isObject(this.originalItem.user_settings)) {
        this.originalItem.user_settings = JSON.parse(this.originalItem.user_settings)
      }
      this.originalItem.roles = JSON.parse(this.originalItem.roles)
      this.item = null;
      await this.reset();
    },
    initSchoolInfo() {
      this.schoolAccount = null
      this.selectedSchoolAccount = null
      this.userSchool = null
      this.selectedSchool = null
    },
    getUserPayload() {
      const omitFields = ['create_date', 'last_login_date', 'roles', 'user_id', 'user_settings', 'type', 'student', 'teacher']
      const payload = _.omit(this.item, omitFields)
      if (_.isEmpty(payload.email)) payload.email = null;
      payload.user_settings = JSON.stringify(this.item.user_settings);
      return payload
    },
    async deleteUserSubscription() {
      let url = '/user/' + this.item.id
      this.item.user_settings = this.user_settings;
      this.item.user_settings.subscription = null
      const {status} = await this.axios.post(url, this.getUserPayload())
      return status
    },
    async removeSchoolAccount() {
      const result = await removeSchoolAccountFromUser(this.item.id)
      let status = result.status
      let success = false
      if (status === 201) {
        status = await this.deleteUserSubscription()
        if (status === 200) {
          success = true
          this.initSchoolInfo()
          if (this.accountSchoolInfo) {
            this.userSchool = this.accountSchoolInfo
            this.selectedSchool = this.accountSchoolInfo
            this.accountSchoolInfo = null
          }
        }
      }
      if (!success) {
        this.notify(`Error: server returned status: ${status}`, 'error')
      }
    },
    async getSchoolAccountDataFromApi(search) {
      let schoolAccountData = []
      if (!search) {
        schoolAccountData = await getPremiumSchools(1, 10)
      } else {
        schoolAccountData = await searchPremiumSchools(search, 1, 10)
      }
      this.schoolAccountList = schoolAccountData || []
      return this.schoolAccountList
    },
    selectSchoolAccount(accountId) {
      if (accountId) {
        this.selectedSchoolAccount = this.schoolAccountList.find(account => account.id === accountId)
      } else {
        this.selectedSchoolAccount = null
      }
    },
    async saveSchoolInfo() {
      let result = {}
      if (this.selectedSchool) {
        result = await connectSchoolToUser(this.selectedSchool.id, this.item.id)
      } else {
        result = await removeSchoolFromUser(this.item.id)
      }
      return result.status === 201
    },
    async save() {
      let url = '/user/' + this.item.id
      this.item.user_settings = this.user_settings;
      if (this.selectedSchoolAccount) {
        this.item.user_settings = {
          ...this.item.user_settings,
          subscription: this.selectedSchoolAccount.subscription
        }
      }
      const {data, status} = await this.axios.post(url, this.getUserPayload())
      if (status !== 200) {
        this.notify(`Error: server returned status: ${status}`, 'error')
      }

      let { success, message } = data
      if (success && this.selectedSchoolAccount) {
        const result = await connectSchoolAccountToUser(this.selectedSchoolAccount.id, this.item.id)
        if (result.status !== 201) {
          success = false
          message = 'Error while saving school account'
        }
      } else {
        if (success && !this.schoolAccount) {
          if (!await this.saveSchoolInfo()) {
            success = false
            message = 'Error while saving school'
          }
        }
      }

      if (success === true) {
        if (this.isStudent) {
          await this.$refs.studentEditor.save()
        }
        this.dialog = false;
        this.originalItem = this.item;
        EventBus.$emit(USER_UPDATED_EVENT, this.originalItem);
        this.$root.$emit('notify', {'message': `Changes saved for ${this.item.username}.`, type: 'success'})
      } else {
        this.notify(message, 'error')
      }

    }
  },
  created() {},
  destroyed() {},
  data() {
    return {
      loading: true,
      dialog: false,
      item: {},
      treeRootNode: {},
      user_settings: _.cloneDeep(DEFAULT_USER_SETTINGS),
			schoolList: [],
      schoolAccountList: [],
      userSchool: null,
			selectedSchool: null,
      isPremiumSchoolVisible: false,
      schoolAccount: null,
      accountSchoolInfo: null,
      selectedSchoolAccount: null
    }
  },

}
</script>

<style scoped>
.school-search-select, .school-account-select {
	display: block;
	margin-left: -16px !important;
}
.school-account-select {
  width: 100%;
}
</style>