export const VERSION = '2.2.9';
export const ROLES = {
    1: 'USER',
    2: 'ADMIN',
    3: 'STUDENT',
    4: 'TEACHER'
}
export const ROLE_ADMIN = 2
export const ROLE_STUDENT = 3
export const ROLE_TEACHER = 4

export const TIMEZONES = [{
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Abidjan"
}, {"name": "Ghana Mean Time", "offset": "+00:00", "zone_id": "Africa/Accra"}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Africa/Addis_Ababa"
}, {"name": "Central European Time", "offset": "+00:00", "zone_id": "Africa/Algiers"}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Africa/Asmara"
}, {"name": "Eastern African Time", "offset": "+03:00", "zone_id": "Africa/Asmera"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Bamako"
}, {"name": "Western African Time", "offset": "+01:00", "zone_id": "Africa/Bangui"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Banjul"
}, {"name": "Greenwich Mean Time", "offset": "-01:00", "zone_id": "Africa/Bissau"}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Blantyre"
}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Brazzaville"
}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Bujumbura"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Africa/Cairo"}, {
    "name": "Western European Time",
    "offset": "+00:00",
    "zone_id": "Africa/Casablanca"
}, {"name": "Central European Time", "offset": "+00:00", "zone_id": "Africa/Ceuta"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Conakry"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Africa/Dakar"}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Africa/Dar_es_Salaam"
}, {"name": "Eastern African Time", "offset": "+03:00", "zone_id": "Africa/Djibouti"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Douala"
}, {"name": "Western European Time", "offset": "-01:00", "zone_id": "Africa/El_Aaiun"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Freetown"
}, {"name": "Central African Time", "offset": "+02:00", "zone_id": "Africa/Gaborone"}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Harare"
}, {
    "name": "South Africa Standard Time",
    "offset": "+02:00",
    "zone_id": "Africa/Johannesburg"
}, {"name": "Eastern African Time", "offset": "+03:00", "zone_id": "Africa/Kampala"}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Khartoum"
}, {"name": "Central African Time", "offset": "+02:00", "zone_id": "Africa/Kigali"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Kinshasa"
}, {"name": "Western African Time", "offset": "+01:00", "zone_id": "Africa/Lagos"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Libreville"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Africa/Lome"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Luanda"
}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Lubumbashi"
}, {"name": "Central African Time", "offset": "+02:00", "zone_id": "Africa/Lusaka"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Malabo"
}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Maputo"
}, {
    "name": "South Africa Standard Time",
    "offset": "+02:00",
    "zone_id": "Africa/Maseru"
}, {
    "name": "South Africa Standard Time",
    "offset": "+02:00",
    "zone_id": "Africa/Mbabane"
}, {"name": "Eastern African Time", "offset": "+03:00", "zone_id": "Africa/Mogadishu"}, {
    "name": "Greenwich Mean Time",
    "offset": "-00:44",
    "zone_id": "Africa/Monrovia"
}, {"name": "Eastern African Time", "offset": "+03:00", "zone_id": "Africa/Nairobi"}, {
    "name": "Western African Time",
    "offset": "+01:00",
    "zone_id": "Africa/Ndjamena"
}, {"name": "Western African Time", "offset": "+01:00", "zone_id": "Africa/Niamey"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Nouakchott"
}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Ouagadougou"
}, {"name": "Western African Time", "offset": "+01:00", "zone_id": "Africa/Porto-Novo"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Africa/Sao_Tome"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Africa/Timbuktu"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Africa/Tripoli"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Africa/Tunis"}, {
    "name": "Central African Time",
    "offset": "+02:00",
    "zone_id": "Africa/Windhoek"
}, {"name": "Hawaii Standard Time", "offset": "-11:00", "zone_id": "America/Adak"}, {
    "name": "Alaska Standard Time",
    "offset": "-10:00",
    "zone_id": "America/Anchorage"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Anguilla"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Antigua"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Araguaina"
}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Buenos_Aires"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Argentina/Catamarca"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/ComodRivadavia"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Argentina/Cordoba"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Jujuy"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Argentina/La_Rioja"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Mendoza"
}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Rio_Gallegos"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Argentina/Salta"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/San_Juan"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Argentina/San_Luis"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Tucuman"
}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Argentina/Ushuaia"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Aruba"}, {
    "name": "Paraguay Time",
    "offset": "-04:00",
    "zone_id": "America/Asuncion"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Atikokan"
}, {"name": "Hawaii Standard Time", "offset": "-11:00", "zone_id": "America/Atka"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Bahia"
}, {
    "name": "Central Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Bahia_Banderas"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Barbados"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Belem"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Belize"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Blanc-Sablon"}, {
    "name": "Amazon Time",
    "offset": "-04:00",
    "zone_id": "America/Boa_Vista"
}, {"name": "Colombia Time", "offset": "-05:00", "zone_id": "America/Bogota"}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Boise"
}, {"name": "Argentine Time", "offset": "-03:00", "zone_id": "America/Buenos_Aires"}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Cambridge_Bay"
}, {"name": "Amazon Time", "offset": "-04:00", "zone_id": "America/Campo_Grande"}, {
    "name": "Eastern Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Cancun"
}, {"name": "Venezuela Time", "offset": "-04:00", "zone_id": "America/Caracas"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Catamarca"
}, {"name": "French Guiana Time", "offset": "-03:00", "zone_id": "America/Cayenne"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Cayman"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Chicago"
}, {
    "name": "Mountain Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Chihuahua"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Coral_Harbour"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Cordoba"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Costa_Rica"}, {
    "name": "Amazon Time",
    "offset": "-04:00",
    "zone_id": "America/Cuiaba"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Curacao"}, {
    "name": "Greenwich Mean Time",
    "offset": "-03:00",
    "zone_id": "America/Danmarkshavn"
}, {
    "name": "Mountain Standard Time",
    "offset": "-09:00",
    "zone_id": "America/Dawson"
}, {
    "name": "Mountain Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Dawson_Creek"
}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Denver"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Detroit"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Dominica"
}, {"name": "Mountain Standard Time", "offset": "-07:00", "zone_id": "America/Edmonton"}, {
    "name": "Acre Time",
    "offset": "-05:00",
    "zone_id": "America/Eirunepe"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/El_Salvador"
}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Ensenada"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Fort_Wayne"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Fortaleza"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Glace_Bay"
}, {
    "name": "Western Greenland Time",
    "offset": "-03:00",
    "zone_id": "America/Godthab"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Goose_Bay"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Grand_Turk"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Grenada"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Guadeloupe"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Guatemala"}, {
    "name": "Ecuador Time",
    "offset": "-05:00",
    "zone_id": "America/Guayaquil"
}, {"name": "Guyana Time", "offset": "-03:45", "zone_id": "America/Guyana"}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Halifax"
}, {"name": "Cuba Standard Time", "offset": "-05:00", "zone_id": "America/Havana"}, {
    "name": "Mountain Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Hermosillo"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Indianapolis"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Indiana/Knox"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Marengo"
}, {
    "name": "Eastern Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Indiana/Petersburg"
}, {
    "name": "Central Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Tell_City"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Vevay"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Vincennes"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indiana/Winamac"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Indianapolis"
}, {
    "name": "Mountain Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Inuvik"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Iqaluit"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Jamaica"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Jujuy"
}, {"name": "Alaska Standard Time", "offset": "-08:00", "zone_id": "America/Juneau"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Kentucky/Louisville"
}, {
    "name": "Eastern Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Kentucky/Monticello"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Knox_IN"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Kralendijk"}, {
    "name": "Bolivia Time",
    "offset": "-04:00",
    "zone_id": "America/La_Paz"
}, {"name": "Peru Time", "offset": "-05:00", "zone_id": "America/Lima"}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Los_Angeles"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Louisville"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Lower_Princes"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Maceio"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Managua"}, {
    "name": "Amazon Time",
    "offset": "-04:00",
    "zone_id": "America/Manaus"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Marigot"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Martinique"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Matamoros"
}, {"name": "Mountain Standard Time", "offset": "-08:00", "zone_id": "America/Mazatlan"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Mendoza"
}, {
    "name": "Central Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Menominee"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Merida"}, {
    "name": "Alaska Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Metlakatla"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Mexico_City"
}, {
    "name": "Pierre & Miquelon Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Miquelon"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Moncton"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Monterrey"}, {
    "name": "Uruguay Time",
    "offset": "-03:00",
    "zone_id": "America/Montevideo"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Montreal"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Montserrat"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Nassau"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/New_York"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Nipigon"}, {
    "name": "Alaska Standard Time",
    "offset": "-11:00",
    "zone_id": "America/Nome"
}, {
    "name": "Fernando de Noronha Time",
    "offset": "-02:00",
    "zone_id": "America/Noronha"
}, {
    "name": "Central Standard Time",
    "offset": "-07:00",
    "zone_id": "America/North_Dakota/Beulah"
}, {
    "name": "Central Standard Time",
    "offset": "-07:00",
    "zone_id": "America/North_Dakota/Center"
}, {
    "name": "Central Standard Time",
    "offset": "-07:00",
    "zone_id": "America/North_Dakota/New_Salem"
}, {
    "name": "Mountain Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Ojinaga"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "America/Panama"}, {
    "name": "Eastern Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Pangnirtung"
}, {"name": "Suriname Time", "offset": "-03:30", "zone_id": "America/Paramaribo"}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Phoenix"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Port-au-Prince"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Port_of_Spain"}, {
    "name": "Acre Time",
    "offset": "-05:00",
    "zone_id": "America/Porto_Acre"
}, {"name": "Amazon Time", "offset": "-04:00", "zone_id": "America/Porto_Velho"}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Puerto_Rico"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Rainy_River"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Rankin_Inlet"}, {
    "name": "Brasilia Time",
    "offset": "-03:00",
    "zone_id": "America/Recife"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "America/Regina"}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Resolute"
}, {"name": "Acre Time", "offset": "-05:00", "zone_id": "America/Rio_Branco"}, {
    "name": "Argentine Time",
    "offset": "-03:00",
    "zone_id": "America/Rosario"
}, {"name": "Pacific Standard Time", "offset": "-08:00", "zone_id": "America/Santa_Isabel"}, {
    "name": "Brasilia Time",
    "offset": "-04:00",
    "zone_id": "America/Santarem"
}, {"name": "Chile Time", "offset": "-03:00", "zone_id": "America/Santiago"}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:30",
    "zone_id": "America/Santo_Domingo"
}, {"name": "Brasilia Time", "offset": "-03:00", "zone_id": "America/Sao_Paulo"}, {
    "name": "Eastern Greenland Time",
    "offset": "-02:00",
    "zone_id": "America/Scoresbysund"
}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Shiprock"
}, {"name": "Alaska Standard Time", "offset": "-08:00", "zone_id": "America/Sitka"}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/St_Barthelemy"
}, {
    "name": "Newfoundland Standard Time",
    "offset": "-03:30",
    "zone_id": "America/St_Johns"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/St_Kitts"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/St_Lucia"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/St_Thomas"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/St_Vincent"
}, {
    "name": "Central Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Swift_Current"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Tegucigalpa"
}, {"name": "Atlantic Standard Time", "offset": "-04:00", "zone_id": "America/Thule"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Thunder_Bay"
}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Tijuana"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "America/Toronto"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Tortola"
}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Vancouver"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "America/Virgin"
}, {
    "name": "Mountain Standard Time",
    "offset": "-08:00",
    "zone_id": "America/Whitehorse"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "America/Winnipeg"
}, {
    "name": "Alaska Standard Time",
    "offset": "-09:00",
    "zone_id": "America/Yakutat"
}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "America/Yellowknife"
}, {
    "name": "Australian Western Standard Time",
    "offset": "+08:00",
    "zone_id": "Antarctica/Casey"
}, {"name": "Davis Time", "offset": "+07:00", "zone_id": "Antarctica/Davis"}, {
    "name": "Dumont-d'Urville Time",
    "offset": "+10:00",
    "zone_id": "Antarctica/DumontDUrville"
}, {
    "name": "Macquarie Island Standard Time",
    "offset": "+11:00",
    "zone_id": "Antarctica/Macquarie"
}, {"name": "Mawson Time", "offset": "+06:00", "zone_id": "Antarctica/Mawson"}, {
    "name": "New Zealand Standard Time",
    "offset": "+12:00",
    "zone_id": "Antarctica/McMurdo"
}, {"name": "Chile Time", "offset": "-03:00", "zone_id": "Antarctica/Palmer"}, {
    "name": "Rothera Time",
    "offset": "+00:00",
    "zone_id": "Antarctica/Rothera"
}, {"name": "New Zealand Standard Time", "offset": "+12:00", "zone_id": "Antarctica/South_Pole"}, {
    "name": "Syowa Time",
    "offset": "+03:00",
    "zone_id": "Antarctica/Syowa"
}, {"name": "Vostok Time", "offset": "+06:00", "zone_id": "Antarctica/Vostok"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Arctic/Longyearbyen"
}, {"name": "Arabia Standard Time", "offset": "+03:00", "zone_id": "Asia/Aden"}, {
    "name": "Alma-Ata Time",
    "offset": "+06:00",
    "zone_id": "Asia/Almaty"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Asia/Amman"}, {
    "name": "Anadyr Time",
    "offset": "+13:00",
    "zone_id": "Asia/Anadyr"
}, {"name": "Aqtau Time", "offset": "+05:00", "zone_id": "Asia/Aqtau"}, {
    "name": "Aqtobe Time",
    "offset": "+05:00",
    "zone_id": "Asia/Aqtobe"
}, {"name": "Turkmenistan Time", "offset": "+05:00", "zone_id": "Asia/Ashgabat"}, {
    "name": "Turkmenistan Time",
    "offset": "+05:00",
    "zone_id": "Asia/Ashkhabad"
}, {"name": "Arabia Standard Time", "offset": "+03:00", "zone_id": "Asia/Baghdad"}, {
    "name": "Arabia Standard Time",
    "offset": "+04:00",
    "zone_id": "Asia/Bahrain"
}, {"name": "Azerbaijan Time", "offset": "+04:00", "zone_id": "Asia/Baku"}, {
    "name": "Indochina Time",
    "offset": "+07:00",
    "zone_id": "Asia/Bangkok"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Asia/Beirut"}, {
    "name": "Kirgizstan Time",
    "offset": "+06:00",
    "zone_id": "Asia/Bishkek"
}, {"name": "Brunei Time", "offset": "+08:00", "zone_id": "Asia/Brunei"}, {
    "name": "India Standard Time",
    "offset": "+05:30",
    "zone_id": "Asia/Calcutta"
}, {"name": "Choibalsan Time", "offset": "+07:00", "zone_id": "Asia/Choibalsan"}, {
    "name": "China Standard Time",
    "offset": "+07:00",
    "zone_id": "Asia/Chongqing"
}, {"name": "China Standard Time", "offset": "+07:00", "zone_id": "Asia/Chungking"}, {
    "name": "India Standard Time",
    "offset": "+05:30",
    "zone_id": "Asia/Colombo"
}, {"name": "Bangladesh Time", "offset": "+06:00", "zone_id": "Asia/Dacca"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Asia/Damascus"
}, {"name": "Bangladesh Time", "offset": "+06:00", "zone_id": "Asia/Dhaka"}, {
    "name": "Timor-Leste Time",
    "offset": "+09:00",
    "zone_id": "Asia/Dili"
}, {"name": "Gulf Standard Time", "offset": "+04:00", "zone_id": "Asia/Dubai"}, {
    "name": "Tajikistan Time",
    "offset": "+06:00",
    "zone_id": "Asia/Dushanbe"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Asia/Gaza"}, {
    "name": "China Standard Time",
    "offset": "+08:30",
    "zone_id": "Asia/Harbin"
}, {"name": "Indochina Time", "offset": "+07:00", "zone_id": "Asia/Ho_Chi_Minh"}, {
    "name": "Hong Kong Time",
    "offset": "+08:00",
    "zone_id": "Asia/Hong_Kong"
}, {"name": "Hovd Time", "offset": "+06:00", "zone_id": "Asia/Hovd"}, {
    "name": "Irkutsk Time",
    "offset": "+08:00",
    "zone_id": "Asia/Irkutsk"
}, {"name": "Turkey Time", "offset": "+02:00", "zone_id": "Asia/Istanbul"}, {
    "name": "West Indonesia Time",
    "offset": "+07:00",
    "zone_id": "Asia/Jakarta"
}, {"name": "East Indonesia Time", "offset": "+09:00", "zone_id": "Asia/Jayapura"}, {
    "name": "Israel Standard Time",
    "offset": "+02:00",
    "zone_id": "Asia/Jerusalem"
}, {"name": "Afghanistan Time", "offset": "+04:30", "zone_id": "Asia/Kabul"}, {
    "name": "Petropavlovsk-Kamchatski Time",
    "offset": "+12:00",
    "zone_id": "Asia/Kamchatka"
}, {"name": "Pakistan Time", "offset": "+05:00", "zone_id": "Asia/Karachi"}, {
    "name": "Xinjiang Standard Time",
    "offset": "+05:00",
    "zone_id": "Asia/Kashgar"
}, {"name": "Nepal Time", "offset": "+05:30", "zone_id": "Asia/Kathmandu"}, {
    "name": "Nepal Time",
    "offset": "+05:30",
    "zone_id": "Asia/Katmandu"
}, {"name": "India Standard Time", "offset": "+05:30", "zone_id": "Asia/Kolkata"}, {
    "name": "Krasnoyarsk Time",
    "offset": "+07:00",
    "zone_id": "Asia/Krasnoyarsk"
}, {"name": "Malaysia Time", "offset": "+07:30", "zone_id": "Asia/Kuala_Lumpur"}, {
    "name": "Malaysia Time",
    "offset": "+08:00",
    "zone_id": "Asia/Kuching"
}, {"name": "Arabia Standard Time", "offset": "+03:00", "zone_id": "Asia/Kuwait"}, {
    "name": "China Standard Time",
    "offset": "+08:00",
    "zone_id": "Asia/Macao"
}, {"name": "China Standard Time", "offset": "+08:00", "zone_id": "Asia/Macau"}, {
    "name": "Magadan Time",
    "offset": "+11:00",
    "zone_id": "Asia/Magadan"
}, {
    "name": "Central Indonesia Time",
    "offset": "+08:00",
    "zone_id": "Asia/Makassar"
}, {"name": "Philippines Standard Time", "offset": "+08:00", "zone_id": "Asia/Manila"}, {
    "name": "Gulf Standard Time",
    "offset": "+04:00",
    "zone_id": "Asia/Muscat"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Asia/Nicosia"}, {
    "name": "Krasnoyarsk Time",
    "offset": "+07:00",
    "zone_id": "Asia/Novokuznetsk"
}, {"name": "Novosibirsk Time", "offset": "+07:00", "zone_id": "Asia/Novosibirsk"}, {
    "name": "Omsk Time",
    "offset": "+06:00",
    "zone_id": "Asia/Omsk"
}, {"name": "Oral Time", "offset": "+05:00", "zone_id": "Asia/Oral"}, {
    "name": "Indochina Time",
    "offset": "+07:00",
    "zone_id": "Asia/Phnom_Penh"
}, {"name": "West Indonesia Time", "offset": "+08:00", "zone_id": "Asia/Pontianak"}, {
    "name": "Korea Standard Time",
    "offset": "+09:00",
    "zone_id": "Asia/Pyongyang"
}, {"name": "Arabia Standard Time", "offset": "+04:00", "zone_id": "Asia/Qatar"}, {
    "name": "Qyzylorda Time",
    "offset": "+05:00",
    "zone_id": "Asia/Qyzylorda"
}, {"name": "Myanmar Time", "offset": "+06:30", "zone_id": "Asia/Rangoon"}, {
    "name": "Arabia Standard Time",
    "offset": "+03:00",
    "zone_id": "Asia/Riyadh"
}, {"name": "Indochina Time", "offset": "+07:00", "zone_id": "Asia/Saigon"}, {
    "name": "Sakhalin Time",
    "offset": "+11:00",
    "zone_id": "Asia/Sakhalin"
}, {"name": "Uzbekistan Time", "offset": "+05:00", "zone_id": "Asia/Samarkand"}, {
    "name": "Korea Standard Time",
    "offset": "+09:00",
    "zone_id": "Asia/Seoul"
}, {"name": "China Standard Time", "offset": "+08:00", "zone_id": "Asia/Shanghai"}, {
    "name": "Singapore Time",
    "offset": "+07:30",
    "zone_id": "Asia/Singapore"
}, {"name": "China Standard Time", "offset": "+08:00", "zone_id": "Asia/Taipei"}, {
    "name": "Uzbekistan Time",
    "offset": "+06:00",
    "zone_id": "Asia/Tashkent"
}, {"name": "Georgia Time", "offset": "+04:00", "zone_id": "Asia/Tbilisi"}, {
    "name": "Iran Standard Time",
    "offset": "+03:30",
    "zone_id": "Asia/Tehran"
}, {"name": "Israel Standard Time", "offset": "+02:00", "zone_id": "Asia/Tel_Aviv"}, {
    "name": "Bhutan Time",
    "offset": "+05:30",
    "zone_id": "Asia/Thimbu"
}, {"name": "Bhutan Time", "offset": "+05:30", "zone_id": "Asia/Thimphu"}, {
    "name": "Japan Standard Time",
    "offset": "+09:00",
    "zone_id": "Asia/Tokyo"
}, {"name": "Central Indonesia Time", "offset": "+08:00", "zone_id": "Asia/Ujung_Pandang"}, {
    "name": "Ulaanbaatar Time",
    "offset": "+07:00",
    "zone_id": "Asia/Ulaanbaatar"
}, {"name": "Ulaanbaatar Time", "offset": "+07:00", "zone_id": "Asia/Ulan_Bator"}, {
    "name": "Xinjiang Standard Time",
    "offset": "+06:00",
    "zone_id": "Asia/Urumqi"
}, {"name": "Indochina Time", "offset": "+07:00", "zone_id": "Asia/Vientiane"}, {
    "name": "Vladivostok Time",
    "offset": "+10:00",
    "zone_id": "Asia/Vladivostok"
}, {"name": "Yakutsk Time", "offset": "+09:00", "zone_id": "Asia/Yakutsk"}, {
    "name": "Yekaterinburg Time",
    "offset": "+05:00",
    "zone_id": "Asia/Yekaterinburg"
}, {"name": "Armenia Time", "offset": "+04:00", "zone_id": "Asia/Yerevan"}, {
    "name": "Azores Time",
    "offset": "-01:00",
    "zone_id": "Atlantic/Azores"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "Atlantic/Bermuda"
}, {"name": "Western European Time", "offset": "+00:00", "zone_id": "Atlantic/Canary"}, {
    "name": "Cape Verde Time",
    "offset": "-02:00",
    "zone_id": "Atlantic/Cape_Verde"
}, {
    "name": "Western European Time",
    "offset": "+00:00",
    "zone_id": "Atlantic/Faeroe"
}, {"name": "Western European Time", "offset": "+00:00", "zone_id": "Atlantic/Faroe"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Atlantic/Jan_Mayen"
}, {"name": "Western European Time", "offset": "+00:00", "zone_id": "Atlantic/Madeira"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Atlantic/Reykjavik"
}, {
    "name": "South Georgia Standard Time",
    "offset": "-02:00",
    "zone_id": "Atlantic/South_Georgia"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Atlantic/St_Helena"}, {
    "name": "Falkland Is. Time",
    "offset": "-04:00",
    "zone_id": "Atlantic/Stanley"
}, {
    "name": "Australian Eastern Standard Time (New South Wales)",
    "offset": "+10:00",
    "zone_id": "Australia/ACT"
}, {
    "name": "Australian Central Standard Time (South Australia)",
    "offset": "+09:30",
    "zone_id": "Australia/Adelaide"
}, {
    "name": "Australian Eastern Standard Time (Queensland)",
    "offset": "+10:00",
    "zone_id": "Australia/Brisbane"
}, {
    "name": "Australian Central Standard Time (South Australia/New South Wales)",
    "offset": "+09:30",
    "zone_id": "Australia/Broken_Hill"
}, {
    "name": "Australian Eastern Standard Time (New South Wales)",
    "offset": "+10:00",
    "zone_id": "Australia/Canberra"
}, {
    "name": "Australian Eastern Standard Time (New South Wales)",
    "offset": "+10:00",
    "zone_id": "Australia/Currie"
}, {
    "name": "Australian Central Standard Time (Northern Territory)",
    "offset": "+09:30",
    "zone_id": "Australia/Darwin"
}, {
    "name": "Australian Central Western Standard Time",
    "offset": "+08:45",
    "zone_id": "Australia/Eucla"
}, {
    "name": "Australian Eastern Standard Time (Tasmania)",
    "offset": "+11:00",
    "zone_id": "Australia/Hobart"
}, {
    "name": "Lord Howe Standard Time",
    "offset": "+10:00",
    "zone_id": "Australia/LHI"
}, {
    "name": "Australian Eastern Standard Time (Queensland)",
    "offset": "+10:00",
    "zone_id": "Australia/Lindeman"
}, {
    "name": "Lord Howe Standard Time",
    "offset": "+10:00",
    "zone_id": "Australia/Lord_Howe"
}, {
    "name": "Australian Eastern Standard Time (Victoria)",
    "offset": "+10:00",
    "zone_id": "Australia/Melbourne"
}, {
    "name": "Australian Eastern Standard Time (New South Wales)",
    "offset": "+10:00",
    "zone_id": "Australia/NSW"
}, {
    "name": "Australian Central Standard Time (Northern Territory)",
    "offset": "+09:30",
    "zone_id": "Australia/North"
}, {
    "name": "Australian Western Standard Time",
    "offset": "+08:00",
    "zone_id": "Australia/Perth"
}, {
    "name": "Australian Eastern Standard Time (Queensland)",
    "offset": "+10:00",
    "zone_id": "Australia/Queensland"
}, {
    "name": "Australian Central Standard Time (South Australia)",
    "offset": "+09:30",
    "zone_id": "Australia/South"
}, {
    "name": "Australian Eastern Standard Time (New South Wales)",
    "offset": "+10:00",
    "zone_id": "Australia/Sydney"
}, {
    "name": "Australian Eastern Standard Time (Tasmania)",
    "offset": "+11:00",
    "zone_id": "Australia/Tasmania"
}, {
    "name": "Australian Eastern Standard Time (Victoria)",
    "offset": "+10:00",
    "zone_id": "Australia/Victoria"
}, {
    "name": "Australian Western Standard Time",
    "offset": "+08:00",
    "zone_id": "Australia/West"
}, {
    "name": "Australian Central Standard Time (South Australia/New South Wales)",
    "offset": "+09:30",
    "zone_id": "Australia/Yancowinna"
}, {"name": "Acre Time", "offset": "-05:00", "zone_id": "Brazil/Acre"}, {
    "name": "Fernando de Noronha Time",
    "offset": "-02:00",
    "zone_id": "Brazil/DeNoronha"
}, {"name": "Brasilia Time", "offset": "-03:00", "zone_id": "Brazil/East"}, {
    "name": "Amazon Time",
    "offset": "-04:00",
    "zone_id": "Brazil/West"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "CET"}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "CST6CDT"
}, {
    "name": "Atlantic Standard Time",
    "offset": "-04:00",
    "zone_id": "Canada/Atlantic"
}, {"name": "Central Standard Time", "offset": "-06:00", "zone_id": "Canada/Central"}, {
    "name": "Greenwich Mean Time",
    "offset": "-06:00",
    "zone_id": "Canada/East-Saskatchewan"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "Canada/Eastern"
}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "Canada/Mountain"
}, {
    "name": "Newfoundland Standard Time",
    "offset": "-03:30",
    "zone_id": "Canada/Newfoundland"
}, {"name": "Pacific Standard Time", "offset": "-08:00", "zone_id": "Canada/Pacific"}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "Canada/Saskatchewan"
}, {"name": "Mountain Standard Time", "offset": "-08:00", "zone_id": "Canada/Yukon"}, {
    "name": "Chile Time",
    "offset": "-03:00",
    "zone_id": "Chile/Continental"
}, {"name": "Easter Is. Time", "offset": "-06:00", "zone_id": "Chile/EasterIsland"}, {
    "name": "Cuba Standard Time",
    "offset": "-05:00",
    "zone_id": "Cuba"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "EET"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "EST"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "EST5EDT"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Egypt"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Eire"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Etc/GMT"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Etc/GMT+0"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Etc/GMT-0"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Etc/GMT0"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Etc/Greenwich"
}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "Etc/UCT"
}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "Etc/UTC"
}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "Etc/Universal"
}, {"name": "Coordinated Universal Time", "offset": "+00:00", "zone_id": "Etc/Zulu"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Amsterdam"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Andorra"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Athens"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Europe/Belfast"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Belgrade"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Berlin"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Bratislava"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Brussels"
}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Bucharest"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Budapest"
}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Chisinau"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Copenhagen"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Europe/Dublin"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Gibraltar"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Europe/Guernsey"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Helsinki"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Europe/Isle_of_Man"}, {
    "name": "Turkey Time",
    "offset": "+02:00",
    "zone_id": "Europe/Istanbul"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "Europe/Jersey"}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Kaliningrad"
}, {"name": "Eastern European Time", "offset": "+03:00", "zone_id": "Europe/Kiev"}, {
    "name": "Western European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Lisbon"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Ljubljana"}, {
    "name": "Greenwich Mean Time",
    "offset": "+01:00",
    "zone_id": "Europe/London"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Luxembourg"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Madrid"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Malta"
}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Mariehamn"
}, {"name": "Moscow Standard Time", "offset": "+03:00", "zone_id": "Europe/Minsk"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Monaco"
}, {"name": "Moscow Standard Time", "offset": "+03:00", "zone_id": "Europe/Moscow"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Nicosia"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Oslo"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Paris"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Podgorica"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Prague"}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Riga"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Rome"}, {
    "name": "Samara Time",
    "offset": "+04:00",
    "zone_id": "Europe/Samara"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/San_Marino"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Sarajevo"}, {
    "name": "Moscow Standard Time",
    "offset": "+03:00",
    "zone_id": "Europe/Simferopol"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Skopje"}, {
    "name": "Eastern European Time",
    "offset": "+02:00",
    "zone_id": "Europe/Sofia"
}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Stockholm"
}, {"name": "Eastern European Time", "offset": "+03:00", "zone_id": "Europe/Tallinn"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Tirane"
}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Tiraspol"
}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Uzhgorod"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Vaduz"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Vatican"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Vienna"}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Vilnius"
}, {
    "name": "Moscow Standard Time",
    "offset": "+04:00",
    "zone_id": "Europe/Volgograd"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Warsaw"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Europe/Zagreb"
}, {
    "name": "Eastern European Time",
    "offset": "+03:00",
    "zone_id": "Europe/Zaporozhye"
}, {"name": "Central European Time", "offset": "+01:00", "zone_id": "Europe/Zurich"}, {
    "name": "Greenwich Mean Time",
    "offset": "+01:00",
    "zone_id": "GB"
}, {"name": "Greenwich Mean Time", "offset": "+01:00", "zone_id": "GB-Eire"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "GMT"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "GMT0"}, {
    "name": "Greenwich Mean Time",
    "offset": "+00:00",
    "zone_id": "Greenwich"
}, {"name": "Hawaii Standard Time", "offset": "-10:00", "zone_id": "HST"}, {
    "name": "Hong Kong Time",
    "offset": "+08:00",
    "zone_id": "Hongkong"
}, {"name": "Greenwich Mean Time", "offset": "+00:00", "zone_id": "Iceland"}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Indian/Antananarivo"
}, {
    "name": "Indian Ocean Territory Time",
    "offset": "+05:00",
    "zone_id": "Indian/Chagos"
}, {"name": "Christmas Island Time", "offset": "+07:00", "zone_id": "Indian/Christmas"}, {
    "name": "Cocos Islands Time",
    "offset": "+06:30",
    "zone_id": "Indian/Cocos"
}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Indian/Comoro"
}, {
    "name": "French Southern & Antarctic Lands Time",
    "offset": "+05:00",
    "zone_id": "Indian/Kerguelen"
}, {"name": "Seychelles Time", "offset": "+04:00", "zone_id": "Indian/Mahe"}, {
    "name": "Maldives Time",
    "offset": "+05:00",
    "zone_id": "Indian/Maldives"
}, {"name": "Mauritius Time", "offset": "+04:00", "zone_id": "Indian/Mauritius"}, {
    "name": "Eastern African Time",
    "offset": "+03:00",
    "zone_id": "Indian/Mayotte"
}, {"name": "Reunion Time", "offset": "+04:00", "zone_id": "Indian/Reunion"}, {
    "name": "Iran Standard Time",
    "offset": "+03:30",
    "zone_id": "Iran"
}, {"name": "Israel Standard Time", "offset": "+02:00", "zone_id": "Israel"}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "Jamaica"
}, {"name": "Japan Standard Time", "offset": "+09:00", "zone_id": "Japan"}, {
    "name": "Marshall Islands Time",
    "offset": "-12:00",
    "zone_id": "Kwajalein"
}, {"name": "Eastern European Time", "offset": "+02:00", "zone_id": "Libya"}, {
    "name": "Middle Europe Time",
    "offset": "+01:00",
    "zone_id": "MET"
}, {"name": "Mountain Standard Time", "offset": "-07:00", "zone_id": "MST"}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "MST7MDT"
}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "Mexico/BajaNorte"
}, {
    "name": "Mountain Standard Time",
    "offset": "-08:00",
    "zone_id": "Mexico/BajaSur"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "Mexico/General"
}, {"name": "New Zealand Standard Time", "offset": "+12:00", "zone_id": "NZ"}, {
    "name": "Chatham Standard Time",
    "offset": "+12:45",
    "zone_id": "NZ-CHAT"
}, {"name": "Mountain Standard Time", "offset": "-07:00", "zone_id": "Navajo"}, {
    "name": "China Standard Time",
    "offset": "+08:00",
    "zone_id": "PRC"
}, {"name": "Pacific Standard Time", "offset": "-08:00", "zone_id": "PST8PDT"}, {
    "name": "West Samoa Standard Time",
    "offset": "-11:00",
    "zone_id": "Pacific/Apia"
}, {
    "name": "New Zealand Standard Time",
    "offset": "+12:00",
    "zone_id": "Pacific/Auckland"
}, {"name": "Chatham Standard Time", "offset": "+12:45", "zone_id": "Pacific/Chatham"}, {
    "name": "Chuuk Time",
    "offset": "+10:00",
    "zone_id": "Pacific/Chuuk"
}, {"name": "Easter Is. Time", "offset": "-06:00", "zone_id": "Pacific/Easter"}, {
    "name": "Vanuatu Time",
    "offset": "+11:00",
    "zone_id": "Pacific/Efate"
}, {"name": "Phoenix Is. Time", "offset": "-12:00", "zone_id": "Pacific/Enderbury"}, {
    "name": "Tokelau Time",
    "offset": "-10:00",
    "zone_id": "Pacific/Fakaofo"
}, {"name": "Fiji Time", "offset": "+12:00", "zone_id": "Pacific/Fiji"}, {
    "name": "Tuvalu Time",
    "offset": "+12:00",
    "zone_id": "Pacific/Funafuti"
}, {"name": "Galapagos Time", "offset": "-05:00", "zone_id": "Pacific/Galapagos"}, {
    "name": "Gambier Time",
    "offset": "-09:00",
    "zone_id": "Pacific/Gambier"
}, {
    "name": "Solomon Is. Time",
    "offset": "+11:00",
    "zone_id": "Pacific/Guadalcanal"
}, {"name": "Chamorro Standard Time", "offset": "+10:00", "zone_id": "Pacific/Guam"}, {
    "name": "Hawaii Standard Time",
    "offset": "-10:00",
    "zone_id": "Pacific/Honolulu"
}, {"name": "Hawaii Standard Time", "offset": "-10:00", "zone_id": "Pacific/Johnston"}, {
    "name": "Line Is. Time",
    "offset": "-10:40",
    "zone_id": "Pacific/Kiritimati"
}, {"name": "Kosrae Time", "offset": "+12:00", "zone_id": "Pacific/Kosrae"}, {
    "name": "Marshall Islands Time",
    "offset": "-12:00",
    "zone_id": "Pacific/Kwajalein"
}, {"name": "Marshall Islands Time", "offset": "+12:00", "zone_id": "Pacific/Majuro"}, {
    "name": "Marquesas Time",
    "offset": "-09:30",
    "zone_id": "Pacific/Marquesas"
}, {"name": "Samoa Standard Time", "offset": "-11:00", "zone_id": "Pacific/Midway"}, {
    "name": "Nauru Time",
    "offset": "+11:30",
    "zone_id": "Pacific/Nauru"
}, {"name": "Niue Time", "offset": "-11:30", "zone_id": "Pacific/Niue"}, {
    "name": "Norfolk Time",
    "offset": "+11:30",
    "zone_id": "Pacific/Norfolk"
}, {"name": "New Caledonia Time", "offset": "+11:00", "zone_id": "Pacific/Noumea"}, {
    "name": "Samoa Standard Time",
    "offset": "-11:00",
    "zone_id": "Pacific/Pago_Pago"
}, {"name": "Palau Time", "offset": "+09:00", "zone_id": "Pacific/Palau"}, {
    "name": "Pitcairn Standard Time",
    "offset": "-08:30",
    "zone_id": "Pacific/Pitcairn"
}, {"name": "Pohnpei Time", "offset": "+11:00", "zone_id": "Pacific/Pohnpei"}, {
    "name": "Pohnpei Time",
    "offset": "+11:00",
    "zone_id": "Pacific/Ponape"
}, {"name": "Papua New Guinea Time", "offset": "+10:00", "zone_id": "Pacific/Port_Moresby"}, {
    "name": "Cook Is. Time",
    "offset": "-10:30",
    "zone_id": "Pacific/Rarotonga"
}, {"name": "Chamorro Standard Time", "offset": "+10:00", "zone_id": "Pacific/Saipan"}, {
    "name": "Samoa Standard Time",
    "offset": "-11:00",
    "zone_id": "Pacific/Samoa"
}, {"name": "Tahiti Time", "offset": "-10:00", "zone_id": "Pacific/Tahiti"}, {
    "name": "Gilbert Is. Time",
    "offset": "+12:00",
    "zone_id": "Pacific/Tarawa"
}, {"name": "Tonga Time", "offset": "+13:00", "zone_id": "Pacific/Tongatapu"}, {
    "name": "Chuuk Time",
    "offset": "+10:00",
    "zone_id": "Pacific/Truk"
}, {"name": "Wake Time", "offset": "+12:00", "zone_id": "Pacific/Wake"}, {
    "name": "Wallis & Futuna Time",
    "offset": "+12:00",
    "zone_id": "Pacific/Wallis"
}, {"name": "Chuuk Time", "offset": "+10:00", "zone_id": "Pacific/Yap"}, {
    "name": "Central European Time",
    "offset": "+01:00",
    "zone_id": "Poland"
}, {"name": "Western European Time", "offset": "+01:00", "zone_id": "Portugal"}, {
    "name": "Greenwich Mean Time",
    "offset": "+08:00",
    "zone_id": "ROC"
}, {"name": "Korea Standard Time", "offset": "+09:00", "zone_id": "ROK"}, {
    "name": "Singapore Time",
    "offset": "+07:30",
    "zone_id": "Singapore"
}, {"name": "Turkey Time", "offset": "+02:00", "zone_id": "Turkey"}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "UCT"
}, {"name": "Alaska Standard Time", "offset": "-10:00", "zone_id": "US/Alaska"}, {
    "name": "Hawaii Standard Time",
    "offset": "-11:00",
    "zone_id": "US/Aleutian"
}, {"name": "Mountain Standard Time", "offset": "-07:00", "zone_id": "US/Arizona"}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "US/Central"
}, {
    "name": "Eastern Standard Time",
    "offset": "-05:00",
    "zone_id": "US/East-Indiana"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "US/Eastern"}, {
    "name": "Hawaii Standard Time",
    "offset": "-10:00",
    "zone_id": "US/Hawaii"
}, {
    "name": "Central Standard Time",
    "offset": "-06:00",
    "zone_id": "US/Indiana-Starke"
}, {"name": "Eastern Standard Time", "offset": "-05:00", "zone_id": "US/Michigan"}, {
    "name": "Mountain Standard Time",
    "offset": "-07:00",
    "zone_id": "US/Mountain"
}, {"name": "Pacific Standard Time", "offset": "-08:00", "zone_id": "US/Pacific"}, {
    "name": "Pacific Standard Time",
    "offset": "-08:00",
    "zone_id": "US/Pacific-New"
}, {"name": "Samoa Standard Time", "offset": "-11:00", "zone_id": "US/Samoa"}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "UTC"
}, {"name": "Coordinated Universal Time", "offset": "+00:00", "zone_id": "Universal"}, {
    "name": "Moscow Standard Time",
    "offset": "+03:00",
    "zone_id": "W-SU"
}, {"name": "Western European Time", "offset": "+00:00", "zone_id": "WET"}, {
    "name": "Coordinated Universal Time",
    "offset": "+00:00",
    "zone_id": "Zulu"
}]

export const SUB_SERVICE_SECRET = 'dsafhk2fdasjk239!4'

// For Production
// export const CMS_API_PREFIX = 'https://bo2api.readtheory.org/admin'
// export const SUB_SERVICE_API = 'https://subscriptions-service.readtheory.org'
// export const SCHOOL_SERVICE_API = 'https://prod.readtheory.org'

// For Development
export const CMS_API_PREFIX = 'https://readtheory-staging.readtheory.org/admin'
export const SUB_SERVICE_API = 'https://readtheory-staging-subscription.readtheory.org'
export const SCHOOL_SERVICE_API = 'https://readtheory-staging.readtheory.org'
 
export const SCHOOL_ACCOUNT_STATUS_LIST = [
  'Trial',
  'PO Received / Invoiced',
  'Paid and active',
  'Behind on Payment',
  'Disabled'
]

export const STUDENTS_COUNT_LIST = [
  '< 100', '100 - 300', '300 - 600', '600 - 1000', '1000+'
]

export const COUNTRY_DATA = [
  { name: "Afghanistan", alpha2: "AF", alpha3: "AFG" },
  { name: "Åland Islands", alpha2: "AX", alpha3: "ALA" },
  { name: "Albania", alpha2: "AL", alpha3: "ALB" },
  { name: "Algeria", alpha2: "DZ", alpha3: "DZA" },
  { name: "American Samoa", alpha2: "AS", alpha3: "ASM" },
  { name: "Andorra", alpha2: "AD", alpha3: "AND" },
  { name: "Angola", alpha2: "AO", alpha3: "AGO" },
  { name: "Anguilla", alpha2: "AI", alpha3: "AIA" },
  { name: "Antarctica", alpha2: "AQ", alpha3: "ATA" },
  { name: "Antigua and Barbuda", alpha2: "AG", alpha3: "ATG" },
  { name: "Argentina", alpha2: "AR", alpha3: "ARG" },
  { name: "Armenia", alpha2: "AM", alpha3: "ARM" },
  { name: "Aruba", alpha2: "AW", alpha3: "ABW" },
  { name: "Australia", alpha2: "AU", alpha3: "AUS" },
  { name: "Austria", alpha2: "AT", alpha3: "AUT" },
  { name: "Azerbaijan", alpha2: "AZ", alpha3: "AZE" },
  { name: "Bahamas", alpha2: "BS", alpha3: "BHS" },
  { name: "Bahrain", alpha2: "BH", alpha3: "BHR" },
  { name: "Bangladesh", alpha2: "BD", alpha3: "BGD" },
  { name: "Barbados", alpha2: "BB", alpha3: "BRB" },
  { name: "Belarus", alpha2: "BY", alpha3: "BLR" },
  { name: "Belgium", alpha2: "BE", alpha3: "BEL" },
  { name: "Belize", alpha2: "BZ", alpha3: "BLZ" },
  { name: "Benin", alpha2: "BJ", alpha3: "BEN" },
  { name: "Bermuda", alpha2: "BM", alpha3: "BMU" },
  { name: "Bhutan", alpha2: "BT", alpha3: "BTN" },
  { name: "Bolivia (Plurinational State of)", alpha2: "BO", alpha3: "BOL" },
  { name: "Bonaire, Sint Eustatius and Saba", alpha2: "BQ", alpha3: "BES" },
  { name: "Bosnia and Herzegovina", alpha2: "BA", alpha3: "BIH" },
  { name: "Botswana", alpha2: "BW", alpha3: "BWA" },
  { name: "Bouvet Island", alpha2: "BV", alpha3: "BVT" },
  { name: "Brazil", alpha2: "BR", alpha3: "BRA" },
  { name: "British Indian Ocean Territory", alpha2: "IO", alpha3: "IOT" },
  { name: "Brunei Darussalam", alpha2: "BN", alpha3: "BRN" },
  { name: "Bulgaria", alpha2: "BG", alpha3: "BGR" },
  { name: "Burkina Faso", alpha2: "BF", alpha3: "BFA" },
  { name: "Burundi", alpha2: "BI", alpha3: "BDI" },
  { name: "Cabo Verde", alpha2: "CV", alpha3: "CPV" },
  { name: "Cambodia", alpha2: "KH", alpha3: "KHM" },
  { name: "Cameroon", alpha2: "CM", alpha3: "CMR" },
  { name: "Canada", alpha2: "CA", alpha3: "CAN" },
  { name: "Cayman Islands", alpha2: "KY", alpha3: "CYM" },
  { name: "Central African Republic", alpha2: "CF", alpha3: "CAF" },
  { name: "Chad", alpha2: "TD", alpha3: "TCD" },
  { name: "Chile", alpha2: "CL", alpha3: "CHL" },
  { name: "China", alpha2: "CN", alpha3: "CHN" },
  { name: "Christmas Island", alpha2: "CX", alpha3: "CXR" },
  { name: "Cocos (Keeling) Islands", alpha2: "CC", alpha3: "CCK" },
  { name: "Colombia", alpha2: "CO", alpha3: "COL" },
  { name: "Comoros", alpha2: "KM", alpha3: "COM" },
  { name: "Congo", alpha2: "CG", alpha3: "COG" },
  { name: "Congo, Democratic Republic of the", alpha2: "CD", alpha3: "COD" },
  { name: "Cook Islands", alpha2: "CK", alpha3: "COK" },
  { name: "Costa Rica", alpha2: "CR", alpha3: "CRI" },
  { name: "Côte d'Ivoire", alpha2: "CI", alpha3: "CIV" },
  { name: "Croatia", alpha2: "HR", alpha3: "HRV" },
  { name: "Cuba", alpha2: "CU", alpha3: "CUB" },
  { name: "Curaçao", alpha2: "CW", alpha3: "CUW" },
  { name: "Cyprus", alpha2: "CY", alpha3: "CYP" },
  { name: "Czechia", alpha2: "CZ", alpha3: "CZE" },
  { name: "Denmark", alpha2: "DK", alpha3: "DNK" },
  { name: "Djibouti", alpha2: "DJ", alpha3: "DJI" },
  { name: "Dominica", alpha2: "DM", alpha3: "DMA" },
  { name: "Dominican Republic", alpha2: "DO", alpha3: "DOM" },
  { name: "Ecuador", alpha2: "EC", alpha3: "ECU" },
  { name: "Egypt", alpha2: "EG", alpha3: "EGY" },
  { name: "El Salvador", alpha2: "SV", alpha3: "SLV" },
  { name: "Equatorial Guinea", alpha2: "GQ", alpha3: "GNQ" },
  { name: "Eritrea", alpha2: "ER", alpha3: "ERI" },
  { name: "Estonia", alpha2: "EE", alpha3: "EST" },
  { name: "Eswatini", alpha2: "SZ", alpha3: "SWZ" },
  { name: "Ethiopia", alpha2: "ET", alpha3: "ETH" },
  { name: "Falkland Islands (Malvinas)", alpha2: "FK", alpha3: "FLK" },
  { name: "Faroe Islands", alpha2: "FO", alpha3: "FRO" },
  { name: "Fiji", alpha2: "FJ", alpha3: "FJI" },
  { name: "Finland", alpha2: "FI", alpha3: "FIN" },
  { name: "France", alpha2: "FR", alpha3: "FRA" },
  { name: "French Guiana", alpha2: "GF", alpha3: "GUF" },
  { name: "French Polynesia", alpha2: "PF", alpha3: "PYF" },
  { name: "French Southern Territories", alpha2: "TF", alpha3: "ATF" },
  { name: "Gabon", alpha2: "GA", alpha3: "GAB" },
  { name: "Gambia", alpha2: "GM", alpha3: "GMB" },
  { name: "Georgia", alpha2: "GE", alpha3: "GEO" },
  { name: "Germany", alpha2: "DE", alpha3: "DEU" },
  { name: "Ghana", alpha2: "GH", alpha3: "GHA" },
  { name: "Gibraltar", alpha2: "GI", alpha3: "GIB" },
  { name: "Greece", alpha2: "GR", alpha3: "GRC" },
  { name: "Greenland", alpha2: "GL", alpha3: "GRL" },
  { name: "Grenada", alpha2: "GD", alpha3: "GRD" },
  { name: "Guadeloupe", alpha2: "GP", alpha3: "GLP" },
  { name: "Guam", alpha2: "GU", alpha3: "GUM" },
  { name: "Guatemala", alpha2: "GT", alpha3: "GTM" },
  { name: "Guernsey", alpha2: "GG", alpha3: "GGY" },
  { name: "Guinea", alpha2: "GN", alpha3: "GIN" },
  { name: "Guinea-Bissau", alpha2: "GW", alpha3: "GNB" },
  { name: "Guyana", alpha2: "GY", alpha3: "GUY" },
  { name: "Haiti", alpha2: "HT", alpha3: "HTI" },
  { name: "Heard Island and McDonald Islands", alpha2: "HM", alpha3: "HMD" },
  { name: "Holy See", alpha2: "VA", alpha3: "VAT" },
  { name: "Honduras", alpha2: "HN", alpha3: "HND" },
  { name: "Hong Kong", alpha2: "HK", alpha3: "HKG" },
  { name: "Hungary", alpha2: "HU", alpha3: "HUN" },
  { name: "Iceland", alpha2: "IS", alpha3: "ISL" },
  { name: "India", alpha2: "IN", alpha3: "IND" },
  { name: "Indonesia", alpha2: "ID", alpha3: "IDN" },
  { name: "Iran (Islamic Republic of)", alpha2: "IR", alpha3: "IRN" },
  { name: "Iraq", alpha2: "IQ", alpha3: "IRQ" },
  { name: "Ireland", alpha2: "IE", alpha3: "IRL" },
  { name: "Isle of Man", alpha2: "IM", alpha3: "IMN" },
  { name: "Israel", alpha2: "IL", alpha3: "ISR" },
  { name: "Italy", alpha2: "IT", alpha3: "ITA" },
  { name: "Jamaica", alpha2: "JM", alpha3: "JAM" },
  { name: "Japan", alpha2: "JP", alpha3: "JPN" },
  { name: "Jersey", alpha2: "JE", alpha3: "JEY" },
  { name: "Jordan", alpha2: "JO", alpha3: "JOR" },
  { name: "Kazakhstan", alpha2: "KZ", alpha3: "KAZ" },
  { name: "Kenya", alpha2: "KE", alpha3: "KEN" },
  { name: "Kiribati", alpha2: "KI", alpha3: "KIR" },
  { name: "Korea (Democratic People's Republic of)", alpha2: "KP", alpha3: "PRK" },
  { name: "Korea, Republic of", alpha2: "KR", alpha3: "KOR" },
  { name: "Kuwait", alpha2: "KW", alpha3: "KWT" },
  { name: "Kyrgyzstan", alpha2: "KG", alpha3: "KGZ" },
  { name: "Lao People's Democratic Republic", alpha2: "LA", alpha3: "LAO" },
  { name: "Latvia", alpha2: "LV", alpha3: "LVA" },
  { name: "Lebanon", alpha2: "LB", alpha3: "LBN" },
  { name: "Lesotho", alpha2: "LS", alpha3: "LSO" },
  { name: "Liberia", alpha2: "LR", alpha3: "LBR" },
  { name: "Libya", alpha2: "LY", alpha3: "LBY" },
  { name: "Liechtenstein", alpha2: "LI", alpha3: "LIE" },
  { name: "Lithuania", alpha2: "LT", alpha3: "LTU" },
  { name: "Luxembourg", alpha2: "LU", alpha3: "LUX" },
  { name: "Macao", alpha2: "MO", alpha3: "MAC" },
  { name: "Madagascar", alpha2: "MG", alpha3: "MDG" },
  { name: "Malawi", alpha2: "MW", alpha3: "MWI" },
  { name: "Malaysia", alpha2: "MY", alpha3: "MYS" },
  { name: "Maldives", alpha2: "MV", alpha3: "MDV" },
  { name: "Mali", alpha2: "ML", alpha3: "MLI" },
  { name: "Malta", alpha2: "MT", alpha3: "MLT" },
  { name: "Marshall Islands", alpha2: "MH", alpha3: "MHL" },
  { name: "Martinique", alpha2: "MQ", alpha3: "MTQ" },
  { name: "Mauritania", alpha2: "MR", alpha3: "MRT" },
  { name: "Mauritius", alpha2: "MU", alpha3: "MUS" },
  { name: "Mayotte", alpha2: "YT", alpha3: "MYT" },
  { name: "Mexico", alpha2: "MX", alpha3: "MEX" },
  { name: "Micronesia (Federated States of)", alpha2: "FM", alpha3: "FSM" },
  { name: "Moldova, Republic of", alpha2: "MD", alpha3: "MDA" },
  { name: "Monaco", alpha2: "MC", alpha3: "MCO" },
  { name: "Mongolia", alpha2: "MN", alpha3: "MNG" },
  { name: "Montenegro", alpha2: "ME", alpha3: "MNE" },
  { name: "Montserrat", alpha2: "MS", alpha3: "MSR" },
  { name: "Morocco", alpha2: "MA", alpha3: "MAR" },
  { name: "Mozambique", alpha2: "MZ", alpha3: "MOZ" },
  { name: "Myanmar", alpha2: "MM", alpha3: "MMR" },
  { name: "Namibia", alpha2: "NA", alpha3: "NAM" },
  { name: "Nauru", alpha2: "NR", alpha3: "NRU" },
  { name: "Nepal", alpha2: "NP", alpha3: "NPL" },
  { name: "Netherlands", alpha2: "NL", alpha3: "NLD" },
  { name: "New Caledonia", alpha2: "NC", alpha3: "NCL" },
  { name: "New Zealand", alpha2: "NZ", alpha3: "NZL" },
  { name: "Nicaragua", alpha2: "NI", alpha3: "NIC" },
  { name: "Niger", alpha2: "NE", alpha3: "NER" },
  { name: "Nigeria", alpha2: "NG", alpha3: "NGA" },
  { name: "Niue", alpha2: "NU", alpha3: "NIU" },
  { name: "Norfolk Island", alpha2: "NF", alpha3: "NFK" },
  { name: "North Macedonia", alpha2: "MK", alpha3: "MKD" },
  { name: "Northern Mariana Islands", alpha2: "MP", alpha3: "MNP" },
  { name: "Norway", alpha2: "NO", alpha3: "NOR" },
  { name: "Oman", alpha2: "OM", alpha3: "OMN" },
  { name: "Pakistan", alpha2: "PK", alpha3: "PAK" },
  { name: "Palau", alpha2: "PW", alpha3: "PLW" },
  { name: "Palestine, State of", alpha2: "PS", alpha3: "PSE" },
  { name: "Panama", alpha2: "PA", alpha3: "PAN" },
  { name: "Papua New Guinea", alpha2: "PG", alpha3: "PNG" },
  { name: "Paraguay", alpha2: "PY", alpha3: "PRY" },
  { name: "Peru", alpha2: "PE", alpha3: "PER" },
  { name: "Philippines", alpha2: "PH", alpha3: "PHL" },
  { name: "Pitcairn", alpha2: "PN", alpha3: "PCN" },
  { name: "Poland", alpha2: "PL", alpha3: "POL" },
  { name: "Portugal", alpha2: "PT", alpha3: "PRT" },
  { name: "Puerto Rico", alpha2: "PR", alpha3: "PRI" },
  { name: "Qatar", alpha2: "QA", alpha3: "QAT" },
  { name: "Réunion", alpha2: "RE", alpha3: "REU" },
  { name: "Romania", alpha2: "RO", alpha3: "ROU" },
  { name: "Russian Federation", alpha2: "RU", alpha3: "RUS" },
  { name: "Rwanda", alpha2: "RW", alpha3: "RWA" },
  { name: "Saint Barthélemy", alpha2: "BL", alpha3: "BLM" },
  { name: "Saint Helena, Ascension and Tristan da Cunha",  alpha2: "SH", alpha3: "SHN" },
  { name: "Saint Kitts and Nevis", alpha2: "KN", alpha3: "KNA" },
  { name: "Saint Lucia", alpha2: "LC", alpha3: "LCA" },
  { name: "Saint Martin (French part)", alpha2: "MF", alpha3: "MAF" },
  { name: "Saint Pierre and Miquelon", alpha2: "PM", alpha3: "SPM" },
  { name: "Saint Vincent and the Grenadines", alpha2: "VC", alpha3: "VCT" },
  { name: "Samoa", alpha2: "WS", alpha3: "WSM" },
  { name: "San Marino", alpha2: "SM", alpha3: "SMR" },
  { name: "Sao Tome and Principe", alpha2: "ST", alpha3: "STP" },
  { name: "Saudi Arabia", alpha2: "SA", alpha3: "SAU" },
  { name: "Senegal", alpha2: "SN", alpha3: "SEN" },
  { name: "Serbia", alpha2: "RS", alpha3: "SRB" },
  { name: "Seychelles", alpha2: "SC", alpha3: "SYC" },
  { name: "Sierra Leone", alpha2: "SL", alpha3: "SLE" },
  { name: "Singapore", alpha2: "SG", alpha3: "SGP" },
  { name: "Sint Maarten (Dutch part)", alpha2: "SX", alpha3: "SXM" },
  { name: "Slovakia", alpha2: "SK", alpha3: "SVK" },
  { name: "Slovenia", alpha2: "SI", alpha3: "SVN" },
  { name: "Solomon Islands", alpha2: "SB", alpha3: "SLB" },
  { name: "Somalia", alpha2: "SO", alpha3: "SOM" },
  { name: "South Africa", alpha2: "ZA", alpha3: "ZAF" },
  { name: "South Georgia and the South Sandwich Islands", alpha2: "GS", alpha3: "SGS" },
  { name: "South Sudan", alpha2: "SS", alpha3: "SSD" },
  { name: "Spain", alpha2: "ES", alpha3: "ESP" },
  { name: "Sri Lanka", alpha2: "LK", alpha3: "LKA" },
  { name: "Sudan", alpha2: "SD", alpha3: "SDN" },
  { name: "Suriname", alpha2: "SR", alpha3: "SUR" },
  { name: "Svalbard and Jan Mayen", alpha2: "SJ", alpha3: "SJM" },
  { name: "Sweden", alpha2: "SE", alpha3: "SWE" },
  { name: "Switzerland", alpha2: "CH", alpha3: "CHE" },
  { name: "Syrian Arab Republic", alpha2: "SY", alpha3: "SYR" },
  { name: "Taiwan, Province of China", alpha2: "TW", alpha3: "TWN" },
  { name: "Tajikistan", alpha2: "TJ", alpha3: "TJK" },
  { name: "Tanzania, United Republic of", alpha2: "TZ", alpha3: "TZA" },
  { name: "Thailand", alpha2: "TH", alpha3: "THA" },
  { name: "Timor-Leste", alpha2: "TL", alpha3: "TLS" },
  { name: "Togo", alpha2: "TG", alpha3: "TGO" },
  { name: "Tokelau", alpha2: "TK", alpha3: "TKL" },
  { name: "Tonga", alpha2: "TO", alpha3: "TON" },
  { name: "Trinidad and Tobago", alpha2: "TT", alpha3: "TTO" },
  { name: "Tunisia", alpha2: "TN", alpha3: "TUN" },
  { name: "Turkey", alpha2: "TR", alpha3: "TUR" },
  { name: "Turkmenistan", alpha2: "TM", alpha3: "TKM" },
  { name: "Turks and Caicos Islands", alpha2: "TC", alpha3: "TCA" },
  { name: "Tuvalu", alpha2: "TV", alpha3: "TUV" },
  { name: "Uganda", alpha2: "UG", alpha3: "UGA" },
  { name: "Ukraine", alpha2: "UA", alpha3: "UKR" },
  { name: "United Arab Emirates", alpha2: "AE", alpha3: "ARE" },
  { name: "United Kingdom of Great Britain and Northern Ireland", alpha2: "GB", alpha3: "GBR" },
  { name: "United States of America", alpha2: "US", alpha3: "USA" },
  { name: "United States Minor Outlying Islands", alpha2: "UM", alpha3: "UMI" },
  { name: "Uruguay", alpha2: "UY", alpha3: "URY" },
  { name: "Uzbekistan", alpha2: "UZ", alpha3: "UZB" },
  { name: "Vanuatu", alpha2: "VU", alpha3: "VUT" },
  { name: "Venezuela (Bolivarian Republic of)", alpha2: "VE", alpha3: "VEN" },
  { name: "Viet Nam", alpha2: "VN", alpha3: "VNM" },
  { name: "Virgin Islands (British)", alpha2: "VG", alpha3: "VGB" },
  { name: "Virgin Islands (U.S.)", alpha2: "VI", alpha3: "VIR" },
  { name: "Wallis and Futuna", alpha2: "WF", alpha3: "WLF" },
  { name: "Western Sahara", alpha2: "EH", alpha3: "ESH" },
  { name: "Yemen", alpha2: "YE", alpha3: "YEM" },
  { name: "Zambia", alpha2: "ZM", alpha3: "ZMB" },
  { name: "Zimbabwe", alpha2: "ZW", alpha3: "ZWE" },
];
