import _ from "lodash"
import { getAuthToken } from "./authtoken"
import { SCHOOL_SERVICE_API } from "../constants" 

const REQUESTS_TIMEOUT = 90000

export const fetchFromServerWrapper = (url, options = {}) => {
	let { timeout = REQUESTS_TIMEOUT, ...rest } = options
	if (rest.signal) throw new Error("Signal not supported in timeoutable fetch")
	if (getAuthToken()) {
		rest.headers = { 'Authorization': 'Bearer ' + getAuthToken(), ...rest.headers }
	}
	const controller = new AbortController()
	const { signal } = controller
	return new Promise((resolve, reject) => {
		const timer = setTimeout(() => {
			reject(new Error("Request Timed Out"))
			controller.abort()
		}, timeout)
		fetch(url, { signal, ...rest })
			.finally(() => clearTimeout(timer))
			.then(resolve, reject)
	})
}

export const apiGet = async (url, options = null) => {
	options = _.defaultsDeep(options, {
		method: 'GET',
		headers: {'Content-Type': 'application/json'}
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => res.json());
}

export const apiPost = async (url, body, isJson = true) => {
	const options = _.defaultsDeep(options, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		redirect: 'follow',
		body
	})
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => isJson ? res.json() : res)
}

export const apiDelete = async (url, options = {}, isJson= true) => {
	options = _.defaultsDeep(options, {
		method: 'DELETE',
		headers: {'Content-Type': 'application/json'}
	});
	return fetchFromServerWrapper(url, options).then(checkStatusCode(url)).then(res => isJson ? res.json() : res);
}

export async function getSchoolTotalCount() {
	const url = `${SCHOOL_SERVICE_API}/school/count`
	return apiGet(url)
}

export async function getSchools(page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/infos?page=${page}&pageSize=${pageSize}`
	return apiGet(url)
}

export async function searchSchools(schoolName, page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/admin/_search`
	return apiPost(url, JSON.stringify({ query: schoolName, page, pageSize }))
}

export async function createSchools(schools) {
	const url = `${SCHOOL_SERVICE_API}/school/info`
	return apiPost(url, JSON.stringify(schools))
}

export async function updateSchool(school) {
	const url = `${SCHOOL_SERVICE_API}/school/info/${school.id}`
	return apiPost(url, JSON.stringify(school))
}

export async function deleteSchool(schoolId) {
	const url = `${SCHOOL_SERVICE_API}/school/info/${schoolId}`
	return apiDelete(url, {}, false)
}

export async function getCustomSchoolTotalCount() {
	const url = `${SCHOOL_SERVICE_API}/school/customCount`
	return apiGet(url)
}

export async function getCustomSchools(page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/custom?page=${page}&pageSize=${pageSize}`
	return apiGet(url)
}

export async function searchCustomSchools(query, page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/customSearch`
	return apiPost(url, JSON.stringify({ query, page, pageSize }))
}

export async function updateCustomSchool(schoolId, schoolData) {
	const url = `${SCHOOL_SERVICE_API}/school/custom/${schoolId}`
	return apiPost(url, JSON.stringify(schoolData), false)
}

export async function deleteCustomSchool(schoolId) {
	const url = `${SCHOOL_SERVICE_API}/school/custom/${schoolId}`
	return apiDelete(url, {}, false)
}

export async function getPremiumSchoolTotalCount() {
	const url = `${SCHOOL_SERVICE_API}/school/account/count`
	return apiGet(url)
}

export async function getPremiumSchools(page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/account/list?page=${page}&pageSize=${pageSize}`
	return apiGet(url)
}

export async function getPremiumSchoolAccount(accountId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/${accountId}`
	return apiGet(url)
}

export async function searchPremiumSchools(schoolName, page, pageSize) {
	const url = `${SCHOOL_SERVICE_API}/school/account/search`
	return apiPost(url, JSON.stringify({ query: schoolName, page, pageSize }))
}

export async function createPremiumSchool(schoolData) {
	const url = `${SCHOOL_SERVICE_API}/school/account/create`
	return apiPost(url, JSON.stringify(schoolData))
}

export async function updatePremiumSchool(schoolData) {
	const url = `${SCHOOL_SERVICE_API}/school/account/${schoolData.id}`
	return apiPost(url, JSON.stringify(schoolData))
}

export async function deletePremiumSchool(schoolId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/${schoolId}`
	return apiDelete(url, {}, false)
}

export async function getUserSchool(userId) {
	const url = `${SCHOOL_SERVICE_API}/school/info/user/${userId}`
	return apiGet(url)
}

export async function connectSchoolToUser(schoolId, userId) {
	const url = `${SCHOOL_SERVICE_API}/school/${userId}/info/${schoolId}`
	return apiPost(url, null, false)
}

export async function removeSchoolFromUser(userId) {
	const url = `${SCHOOL_SERVICE_API}/school/user/${userId}`
	return apiDelete(url, {}, false)
}

export async function getUserSchoolAccount(userId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/user/${userId}`
	return apiGet(url)
}

export async function connectSchoolAccountToUser(accountId, userId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/${accountId}/user/${userId}`
	return apiPost(url, null, false)
}

export async function removeSchoolAccountFromUser(userId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/disconnect/${userId}`
	return apiPost(url, null, false)
}

export async function getConnectedTeachersForAccount(schoolAccountId) {
	const url = `${SCHOOL_SERVICE_API}/school/account/${schoolAccountId}/teachers`
	return apiGet(url)
}

export async function getMapLocation(country, city, address) {
  const baseUrl = "https://nominatim.openstreetmap.org/search";
  const searchParams = new URLSearchParams({
    format: "json",
    ...(address ? { q: address } : { country, city }),
  });
  const url = new URL(baseUrl);
  url.search = searchParams;
  const apiResponse = await fetch(url.toString());
  return await apiResponse.json();
}

export const checkStatusCode = _.memoize((url = 'unknown') => {
	return (response) => {
		if (response.status === 202 || response.status === 200 || response.status === 201 || response.status === 304) {
			return response
		}
		const error = new Error(`response returned with bad status code`)
		error.response = response
		error.status = response.status
		throw error
	}
})
